import { Router } from 'found'
import { TFunction } from 'i18next'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { fetchTowingPatterns } from '../../actions/api'
import ErrorText from '../../components/ErrorText'
import Page from '../../components/page/Page'
import SearchPageContainer from '../../components/search/SearchPageContainer'
import SearchPageContent from '../../components/search/SearchPageContent'
import TowingSearch from '../../components/towing/search/TowingSearch'
import { Action, AppState, Dispatch, TowingVehiclePattern } from '../../types'

type Props = {
  t: TFunction
  router: Router
  fetchTowingPatterns: () => void
  towingPatterns: Array<TowingVehiclePattern>
  loadingTowing: boolean
  towingError: string
}

export type State = {
  error?: string
  vehicleTypeActive: boolean
  vehicleNumberActive: boolean
}

class TowingSearchPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      error: undefined,
      vehicleTypeActive: false,
      vehicleNumberActive: false,
    }
  }

  componentDidMount() {
    this.props.fetchTowingPatterns()
  }

  activateField(obj: State) {
    this.setState(obj)
  }

  changeValue(obj: State) {
    this.setState(obj)
  }

  render() {
    const { t, router, loadingTowing, towingError, towingPatterns }: Props = this.props

    if (this.state.error !== t(towingError))
      this.changeValue({ ...this.state, error: t(towingError) })

    return (
      <Page overflowVisible>
        <SearchPageContainer>
          {this.state.error && <ErrorText>{this.state.error}</ErrorText>}
        </SearchPageContainer>
        <SearchPageContainer>
          <SearchPageContent>
            <TowingSearch
              activateVehicleNumber={() =>
                this.activateField({
                  ...this.state,
                  ...{ vehicleNumberActive: true, vehicleTypeActive: false },
                })
              }
              activateVehicleType={() =>
                this.activateField({
                  ...this.state,
                  ...{ vehicleNumberActive: false, vehicleTypeActive: true },
                })
              }
              loading={loadingTowing}
              setError={(error) => this.setState({ error })}
              state={this.state}
              router={router}
              towingPatterns={towingPatterns}
              t={t}
            />
          </SearchPageContent>
        </SearchPageContainer>
      </Page>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const loadingTowing = state.towingSearch.loading
  const towingError = state.towingSearch.error
  const towingPatterns = state.towingPatterns.towingPatterns

  return {
    loadingTowing,
    towingError,
    towingPatterns,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTowingPatterns: () => dispatch(fetchTowingPatterns() as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TowingSearchPage))
