import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import moment from 'moment-timezone'
import React from 'react'
import { withTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import { DEFAULT_NOTIFICATION } from '../../constants'
import Close from '../../icons/Close'
import { getColor, theme } from '../../Theme'
import { Color } from '../../Theme'
import { Timestamp } from '../../types'
import Circle from '../circle/Circle'
import LoadingIndicator from '../LoadingIndicator'

export type ColorScheme = {
  day: Color
  night: Color
}

type NotificationBarComponentColors = {
  color?: ColorScheme
  background?: ColorScheme
  border?: ColorScheme
  Bar: string
}

interface BarProps {
  colors: {
    background?: ColorScheme
    border?: ColorScheme
  }
  shadowed?: boolean
  margin?: string
  marginTop?: string
  marginBottom?: string
  marginRight?: string
  marginLeft?: string
  borderTop?: boolean
}

interface TextButtonProps {
  colors?: {
    color?: ColorScheme
  }
  Text?: {
    color: ColorScheme
  }
}
interface CircleTextProps {
  colors: {
    color?: ColorScheme
    border?: ColorScheme
  }
}

const yellowScheme: ColorScheme = { day: 'yellowDark', night: 'primaryYellow' }

const greenScheme: ColorScheme = { day: 'darkGreen', night: 'shallowGreen' }

const Bar = styled.div<BarProps>`
  ${theme.layout.flexRow};
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
  background: ${(p) =>
    getColor(p.theme, [p.colors.background?.day as Color], [p.colors?.background?.night as Color])};
  ${(p) => p.shadowed && theme.effects.shadowLarge};
  ${(p) => p.margin && `margin: ${p.margin};`}
  ${(p) => p.marginTop && `margin-top: ${p.marginTop};`}
  ${(p) => p.marginBottom && `margin-bottom: ${p.marginBottom};`}
  ${(p) => p.marginRight && `margin-right: ${p.marginRight};`}
  ${(p) => p.marginLeft && `margin-left: ${p.marginLeft};`}
  ${(p) =>
    p.borderTop &&
    `border-top: 1px solid ${getColor(
      p.theme,
      [p.colors?.border?.day as Color],
      [p.colors?.border?.night as Color]
    )}70;`}
`

const CircleText = styled(Circle)<CircleTextProps>`
  ${theme.layout.flexRow};
  ${theme.text('larger', 'content', 'bold')};
  box-sizing: border-box;
  max-width: ${theme.spacing.sizes.tiny};
  max-height: ${theme.spacing.sizes.tiny};
  flex: 1;
  justify-content: center;
  ${theme.spacing.all('normal')};
  margin-right: ${theme.spacing.sizes.large};
  cursor: pointer;
  color: ${(p) =>
    getColor(p.theme, [p.colors?.color?.day as Color], [p.colors?.color?.night as Color])};
  border: 1px solid
    ${(p) =>
      getColor(p.theme, [p.colors?.border?.day as Color], [p.colors?.border?.night as Color])};
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Row = styled.div`
  ${theme.layout.flexRow};
  justify-content: flex-start;
  ${theme.spacing.sides('large')};
  ${theme.spacing.ends('small')};
`

const Text = styled.span<TextButtonProps>`
  ${theme.text()};
  color: ${(p) =>
    getColor(p.theme, [p.colors?.color?.day as Color], [p.colors?.color?.night as Color])};

  > p {
    margin: 0;
  }
`

const BoldText = styled(Text)<TextButtonProps>`
  font-weight: 600;
`

const CloseButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
`

const CloseButton = styled.button<TextButtonProps>`
  color: ${(p) =>
    getColor(p.theme, [p.colors?.color?.day as Color], [p.colors?.color?.night as Color])};
  border: none;
  background: inherit;
  ${theme.spacing.all('tiny')};
`

const BarButton = styled.button<TextButtonProps>`
  display: flex;
  justify-content: center;
  ${theme.spacing.sides('large')};
  ${theme.spacing.ends('small')};
  border: none;
  background: inherit;
  border-top: 1px solid
    ${(p) =>
      getColor(p.theme, [p.colors?.color?.day as Color], [p.colors?.color?.night as Color])}80;
  margin: 0 10px;
  align-items: center;
`

const LeftMargin = styled.div`
  flex: 1;
`

const ButtonText = styled.div<TextButtonProps>`
  ${theme.text('normal', 'content', 'bold')};
  color: ${(p) =>
    getColor(p.theme, [p.colors?.color?.day as Color], [p.colors?.color?.night as Color])};
  text-transform: uppercase;
  flex: 0;
`

const RightMargin = styled.div<TextButtonProps>`
  ${theme.text('small', 'content', 'bold')};
  color: ${(p) =>
    getColor(p.theme, [p.colors?.color?.day as Color], [p.colors?.color?.night as Color])}c0;
  text-transform: uppercase;
  flex: 1;
  text-align: end;
`

const LoaderContainer = styled.div<TextButtonProps>`
  ${theme.spacing.ends('small')};
  border-top: 1px solid
    ${(p) =>
      getColor(p.theme, [p.colors?.color?.day as Color], [p.colors?.color?.night as Color])}80;
  margin: 0 10px;
`

const DoneText = styled.div<TextButtonProps>`
  ${theme.text('normal', 'content', 'bold')};
  color: ${(p) =>
    getColor(p.theme, [p.colors?.color?.day as Color], [p.colors?.color?.night as Color])}c0;
  text-transform: uppercase;
`

type notificationBarColorOverrideKeys =
  | 'Bar'
  | 'Circle'
  | 'Text'
  | 'CloseButtonText'
  | 'BarButton'
  | 'ButtonText'
  | 'RightMargin'
  | 'LoaderContainer'
  | 'DoneText'

export type NotificationBarColorOverrides = Record<
  notificationBarColorOverrideKeys,
  Partial<NotificationBarComponentColors>
>

const getColors = (
  defaultText: boolean,
  isAcknowledged: boolean,
  colorOverrides: Partial<NotificationBarColorOverrides>
) => {
  return {
    Bar: {
      background:
        defaultText || isAcknowledged
          ? { day: 'primaryGreenTinted' as Color, night: 'primaryGreen' as Color }
          : { day: 'primaryYellow' as Color, night: 'yellowDark' as Color },
      border: yellowScheme,
    },
    Circle: {
      color: defaultText ? greenScheme : yellowScheme,
      border: defaultText ? greenScheme : yellowScheme,
    },
    Text: { color: defaultText ? greenScheme : yellowScheme },
    CloseButtonText: { color: defaultText ? greenScheme : yellowScheme },
    BarButton: { color: yellowScheme },
    ButtonText: { color: yellowScheme },
    RightMargin: { color: yellowScheme },
    LoaderContainer: { color: yellowScheme },
    DoneText: { color: yellowScheme },
    ...colorOverrides,
  }
}

type Props = {
  title?: string
  body?: string
  markdown?: string
  neutralContent?: boolean
  shadowed?: boolean
  onClose?: () => void
  action?: () => void
  actionText?: string
  loading?: boolean
  error?: unknown
  sentAt?: Timestamp
  doneText?: string
  isAcknowledged?: boolean
  margin?: string
  marginTop?: string
  marginBottom?: string
  marginRight?: string
  marginLeft?: string
  borderTop?: boolean
  colorOverrides?: Partial<NotificationBarColorOverrides>
  t: TFunction
}

const ActionComponent = ({
  error,
  LoaderContainerColor,
  BarButtonColor,
  ButtonTextColor,
  RightMarginColor,
  DoneTextColor,
  loading,
  doneText,
  action,
  actionText,
  sentAt,
}: {
  error?: string
  loading: boolean
  BarButtonColor: Partial<NotificationBarComponentColors>
  ButtonTextColor: Partial<NotificationBarComponentColors>
  RightMarginColor: Partial<NotificationBarComponentColors>
  LoaderContainerColor: Partial<NotificationBarComponentColors>
  DoneTextColor: Partial<NotificationBarComponentColors>
  doneText: string
  action: () => void
  actionText: string
  sentAt: string
}) => {
  return (
    <>
      {error && <BoldText>{error}</BoldText>}
      {loading ? (
        <LoaderContainer colors={LoaderContainerColor}>
          <LoadingIndicator size={'custom'} padded={false} customSize={22} />
        </LoaderContainer>
      ) : (
        <BarButton colors={BarButtonColor} onClick={!doneText ? action : undefined}>
          <LeftMargin></LeftMargin>
          {doneText ? (
            <DoneText colors={DoneTextColor}>{doneText}</DoneText>
          ) : (
            <ButtonText colors={ButtonTextColor}>{actionText}</ButtonText>
          )}
          <RightMargin colors={RightMarginColor}>
            {sentAt ? moment(sentAt).format('H:mm') : null}
          </RightMargin>
        </BarButton>
      )}
    </>
  )
}

const CloseButtonComponent = ({
  buttonColor,
  onClose,
}: {
  buttonColor: Partial<NotificationBarComponentColors>
  onClose: () => void
}) => {
  return (
    <CloseButtonColumn>
      <CloseButton colors={buttonColor} onClick={onClose}>
        <Close />
      </CloseButton>
    </CloseButtonColumn>
  )
}

function NotificationBar({
  title,
  body,
  markdown = '',
  neutralContent,
  shadowed,
  onClose,
  action,
  actionText,
  loading,
  error,
  sentAt,
  doneText,
  isAcknowledged,
  margin,
  marginTop,
  marginBottom,
  marginRight,
  marginLeft,
  borderTop,
  colorOverrides,
  t,
}: Props) {
  if (!markdown && !title && !body) {
    return null
  }

  const useDefaultTextColors: boolean = markdown === DEFAULT_NOTIFICATION || !!neutralContent
  const containsDefaultNotification = markdown.includes(DEFAULT_NOTIFICATION)
  if (containsDefaultNotification) markdown = markdown.replace(DEFAULT_NOTIFICATION, '')

  const colors = getColors(useDefaultTextColors, isAcknowledged, colorOverrides)

  return (
    <Bar
      shadowed={shadowed}
      colors={colors.Bar}
      margin={margin}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginRight={marginRight}
      marginLeft={marginLeft}
      borderTop={borderTop}
    >
      <Column>
        <Row>
          <CircleText colors={colors.Circle}>!</CircleText>
          <Column>
            {containsDefaultNotification && (
              <Text colors={colors.Text}>{DEFAULT_NOTIFICATION}</Text>
            )}
            {title !== '' && <BoldText colors={colors.Text}>{title}</BoldText>}
            {body !== '' && <Text colors={colors.Text}>{body}</Text>}
            {markdown !== '' && (
              <Text colors={colors.Text}>
                <ReactMarkdown>{markdown}</ReactMarkdown>
              </Text>
            )}
          </Column>
        </Row>
        {action && (
          <ActionComponent
            error={(error as React.ReactNode) && typeof error === 'string' && t(error)}
            loading={loading}
            doneText={doneText}
            action={action}
            actionText={actionText}
            sentAt={sentAt}
            LoaderContainerColor={colors.LoaderContainer}
            BarButtonColor={colors.BarButton}
            ButtonTextColor={colors.ButtonText}
            RightMarginColor={colors.RightMargin}
            DoneTextColor={colors.DoneText}
          />
        )}
      </Column>
      {onClose && <CloseButtonComponent buttonColor={colors.CloseButtonText} onClose={onClose} />}
    </Bar>
  )
}

NotificationBar.displayName = 'NotificationBar'

export default withTranslation()(NotificationBar)
