import { createSelector, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '..'
import { fetchSmDisruptionsAction } from '../actions/sm-disruptions'
import { ACTIVE_SM_DISRUPTION_END_MESSAGE_DURATION } from '../constants'
import moment from '../lib/moment-fi'
import { ActionStatus, SmDisruptionMessage } from '../types/Input'

export interface SmDisruptionState {
  status: ActionStatus
  messages: Record<string, SmDisruptionMessage>
  lastOrigin?: string
  error?: string
}

const initialState: SmDisruptionState = {
  status: 'none',
  messages: {},
}

export const smDisruptionSlice = createSlice({
  name: 'sm-disruptions',
  initialState,
  reducers: {
    addSmDisruptionMessage: (state, { payload }) => {
      state.messages[payload.messageId] = payload
      state.lastOrigin = 'push'
    },
    dismissSmDisruptionsNotification: (state) => {
      state.lastOrigin = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSmDisruptionsAction.pending, (state) => {
      state.status = 'loading'
      state.error = undefined
    })

    builder.addCase(fetchSmDisruptionsAction.fulfilled, (state, { payload }) => {
      state.messages = Object.fromEntries(payload.map((message) => [message.messageId, message]))
      state.lastOrigin = 'fetch'
      state.status = 'succeeded'
    })

    builder.addCase(fetchSmDisruptionsAction.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload.message
      }
      state.status = 'failed'
    })
  },
})

export const { addSmDisruptionMessage, dismissSmDisruptionsNotification } =
  smDisruptionSlice.actions

export default smDisruptionSlice.reducer

const isDisruptionActive = (activeMessageCandidate?: SmDisruptionMessage): boolean => {
  if (!activeMessageCandidate) return false
  if (!activeMessageCandidate.isComplete) {
    return true
  } else if (
    moment(activeMessageCandidate.resolvedTimestamp).isAfter(
      moment().subtract(ACTIVE_SM_DISRUPTION_END_MESSAGE_DURATION)
    )
  )
    return true
  else return false
}

export const selectSmDisruptionMessages = (state: RootState) =>
  Object.values(state.smDisruptions.messages)

/**
 * Selector for the most messages by (initiatedTimestamp, resolvedTimestamp)
 */
export const selectSortedSmDisruptionMessages = createSelector(
  selectSmDisruptionMessages,
  (messages: SmDisruptionMessage[]) => {
    return [...messages].sort((a, b) => b.initiatedTimestamp.localeCompare(a.initiatedTimestamp))
  }
)

export const selectSmDisruptionNotification = createSelector(
  (state) => selectSortedSmDisruptionMessages(state),
  (messages: SmDisruptionMessage[]) => (isDisruptionActive(messages[0]) ? messages[0] : null)
)
