import styled from '@emotion/styled'

import { Moment } from '@/src/types'

import { defaultTextColor, px, theme } from '../../Theme'

const Day = styled.div`
  ${theme.text('large', 'content')};
  color: ${defaultTextColor};
  text-align: left;
`
const Date = styled.div<{ position: number }>`
  ${theme.spacing.left('tiny')};
  ${theme.text('larger', 'content')};
  color: ${defaultTextColor};
  margin-top: ${(p) => px(p.position)};
  text-align: left;
  width: ${theme.spacing.sizes.huge};
  flex-shrink: 0;
`

export const CalendarDay = ({ date, hideDate }: { date: Moment; hideDate?: boolean }) => {
  return (
    <Date position={0}>
      {!hideDate && (
        <>
          <Day>{date.format('dd')}</Day>
          {date.format('DD')}
        </>
      )}
    </Date>
  )
}
