export const roleOrder = [
  { name: 'drivers' },
  { name: 'luotsi' },
  { name: 'akulj' },
  { name: 'conductors' },
  { name: 'secondaryConductors' },
  { name: 'akondp' },
  { name: 'waiters' },
  { name: 'secondaryWaiters' },
  { name: 'passengers' },
  { name: 'unknowns' },
]
