import 'react-datepicker/dist/react-datepicker.css'

import styled from '@emotion/styled'
import fi from 'date-fns/locale/fi'
import { Router } from 'found'
import { TFunction } from 'i18next'
import { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { connect } from 'react-redux'

import { fetchHandlingStations } from '../../../actions/api'
import { clearHandlingStations } from '../../../actions/handlingStations'
import ArrowRight from '../../../icons/ArrowRight'
import { TypedDispatch } from '../../../index'
import moment from '../../../lib/moment-fi'
import { color, defaultTextColor, getColor, theme } from '../../../Theme'
import { Action, AppState } from '../../../types'
import { BoldProps, FullProps } from '../../../types/App'
import { MomentInput } from '../../../types/Common'
import { HandlingStation, SearchDeleteParams } from '../../../types/Input'
import LoadingIndicator from '../../LoadingIndicator'
import CardWithSpace from '../../search/card/CardWithSpace'
import DateContainer from '../../search/DateContainer'
import SearchBox from '../../search/SearchBox'
import SearchButton from '../../search/SearchButton'
import SearchContainer from '../../search/SearchContainer'
import SearchHeader from '../../search/SearchHeader'
import SearchHistoryHeader from '../../search/SearchHistoryHeader'
import SearchInput from '../../search/SearchInput'
import SearchPageHeader from '../../search/SearchPageHeader'
import SearchTitle from '../../search/SearchTitle'
import Shadow from '../../Shadow'
interface ConstrainedSearchBoxProps {
  onClick: () => void
  active: boolean
  disabled: string | boolean
  margin?: string
  marginRight?: string
  marginLeft?: string
}

interface Composition {
  trainNumber: string
  date: string
  station: string
  route: {
    origStation: {
      stationAbbreviation: string
    }
  }
}

const UpperSearchContainer = styled(SearchContainer)`
  margin-bottom: 8px;
`

const ConstrainedSearchBox = styled(SearchBox)<ConstrainedSearchBoxProps>`
  min-width: 40%;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const HandlingStationCard = styled(Shadow)`
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${(p) => getColor(p.theme, ['white'], ['black'])};
`

const Text = styled.span<BoldProps>`
  font-weight: ${(p) => (p.bold ? 'bold' : 'normal')};
  color: ${(p) => (p.bold ? getColor(p.theme, ['black'], ['grayDark']) : color('grayDark'))};
  margin-top: auto;
  ${theme.spacing.sides('tiny')}
`

const ManualSearchInfo = styled.div`
  ${theme.text('large', 'decorative')};
  color: ${defaultTextColor};
  margin: ${theme.spacing.sizes.huge} 0;
  max-width: 100%;
  text-align: center;
`

const LinkButton = styled.button<FullProps>`
  ${theme.text('normal', 'content', 'bold')};
  ${(p) => theme.spacing.sides(p.full ? 'large' : 'small')};
  ${(p) => theme.spacing.ends(p.full ? 'small' : 'tiny')};
  background: ${color('primaryBlue')};
  border: none;
  border-radius: ${theme.borderRadius.button};
  color: ${color('white')};
  cursor: pointer;
  width: 70px;
  height: 34px;
  &[disabled] {
    color: ${(p) => getColor(p.theme, ['white'], ['grayDark'])};
    background: ${(p) => getColor(p.theme, ['shallowBlue'], ['nightGray'])};
  }
`

const CardSpinnerContainer = styled.div`
  background: ${(p) => getColor(p.theme, ['white'], ['grayDark'])};
  border: none;
  border-radius: ${theme.borderRadius.button};
  width: 70px;
  height: 34px;
`

const DisableableInput = styled(SearchInput)<{ disabledColor: string | boolean }>`
  background-color: ${(p) =>
    p.disabledColor
      ? getColor(p.theme, ['grayLight'], ['nightGray'])
      : getColor(p.theme, ['white'], ['black'])};
  color: ${(p) =>
    p.disabledColor ? color('grayLight') : getColor(p.theme, ['black'], ['grayBlue'])};
`

type Props = {
  fetchCompositions: () => void
  fetchCompositionsWithParams: (arg0: string, arg1: string, arg2: string) => void
  loading: boolean
  router: Router
  setError: (arg0: string) => void
  state: unknown
  t: TFunction
  compositionsHistory: Composition[]
  deleteCompositionFromHistory: (searchDeleteParams: SearchDeleteParams) => Action
  fetchHandlingStationsFromApi: (journeyDate: string, trainNumber: string) => unknown
  clearHandlingStations: () => unknown
  handlingStations: Array<HandlingStation>
  handlingStationsLoading: boolean
  handlingStationsError: string
}

const CompositionSearch = ({
  t,
  loading,
  fetchCompositionsWithParams,
  compositionsHistory,
  deleteCompositionFromHistory,
  setError,
  handlingStations,
  handlingStationsError,
  handlingStationsLoading,
  fetchHandlingStationsFromApi,
  clearHandlingStations,
}: Props) => {
  useEffect(() => {
    return () => {
      clearHandlingStations()
    }
  }, [clearHandlingStations])

  const handlingSpotStations =
    (handlingStations && handlingStations.filter((station) => station.handlingSpot)) || []

  const [stationsSearched, setStationsSearched] = useState(false)
  const showManualSearch =
    (stationsSearched && !handlingStationsLoading && handlingSpotStations.length === 0) ||
    handlingStationsError

  const [journeyDate, setJourneyDate] = useState(moment().format())
  const [trainNumber, setTrainNumber] = useState('')
  const [departureDate, setDepartureDate] = useState(moment().format())
  const [startStation, setStartStation] = useState('')

  const [selectedHandlingStation, setSelectedHandlingStation] = useState('')

  const [activeField, setActiveField] = useState('')
  const [activeForm, setActiveForm] = useState('')
  const departureDateActive = activeField === 'departureDate'
  const handlingDisabled = activeForm === 'manual' && showManualSearch
  const manualDisabled = activeForm === 'handling'

  const changeJourneyDate = (value: MomentInput) => {
    setActiveField('')
    setJourneyDate(moment(value).format())
  }

  const changeDepartureDate = (value: MomentInput) => {
    setActiveField('')
    setDepartureDate(moment(value).format())
  }

  const validateHandlingStationSearch = () => trainNumber !== '' && journeyDate !== ''
  const validateManualSearch = () =>
    trainNumber !== '' && departureDate !== '' && startStation !== ''

  const fetchStations = (date: MomentInput, trainNumber: string) => {
    setStationsSearched(true)
    setActiveForm('')
    setActiveField('')
    fetchHandlingStationsFromApi(moment(date).format('YYYY-MM-DD'), trainNumber)
  }

  const manualCompositionSearch = (
    date: MomentInput,
    trainNumber: string,
    departureStation: string
  ) => {
    fetchCompositionsWithParams(moment(date).format('YYYY-MM-DD'), trainNumber, departureStation)
  }

  registerLocale('fi', fi)

  return (
    <div>
      <div onClick={() => setActiveForm('handling')}>
        <SearchPageHeader name="compositions" text={t('searchTool.title.compositions')} />
        <UpperSearchContainer
          onKeyDown={
            validateHandlingStationSearch()
              ? (ev) => ev.keyCode === 13 && fetchStations(journeyDate, trainNumber)
              : undefined
          }
        >
          <ConstrainedSearchBox
            marginRight="8px"
            onClick={() => setActiveField('trainNumber')}
            active={activeField === 'trainNumber'}
            disabled={handlingDisabled}
            disableWidth={true}
          >
            <SearchHeader>{t('trainNumber')}</SearchHeader>
            <DisableableInput
              type="number"
              value={trainNumber}
              disabledColor={handlingDisabled}
              onChange={(event) => setTrainNumber(event.target.value)}
            />
          </ConstrainedSearchBox>
          <ConstrainedSearchBox
            marginLeft="8px"
            onClick={() => setActiveField('journeyDate')}
            active={activeField === 'journeyDate'}
            disabled={handlingDisabled}
          >
            <SearchHeader>{t('journeyDate')}</SearchHeader>
            <DisableableInput
              type="text"
              readOnly
              value={moment(journeyDate).format('L')}
              disabledColor={handlingDisabled}
              onChange={() => null}
            />
          </ConstrainedSearchBox>
        </UpperSearchContainer>
        {activeField === 'journeyDate' && (
          <DateContainer>
            <DatePicker
              inline
              locale="fi"
              selected={new Date(moment(journeyDate).toDate())}
              onChange={(value: Date) => changeJourneyDate(value)}
            />
          </DateContainer>
        )}
      </div>
      {handlingStationsLoading ? (
        <LoadingIndicator size="normal" padded={false} />
      ) : (
        <SearchButton
          t={t}
          validate={validateHandlingStationSearch}
          setError={setError}
          errorKey="fillAllMandatoryFields"
          halveTopMargin
          disabled={Boolean(handlingDisabled)}
          onClick={() => fetchStations(journeyDate, trainNumber)}
          text="fetchHandlingStations"
        />
      )}
      {handlingSpotStations.length !== 0 && <SearchTitle>{t('handlingStations')}</SearchTitle>}
      {handlingSpotStations.map((station) => {
        return (
          <HandlingStationCard key={station.ocpCode}>
            <Text bold>{station.ocpCode}</Text>
            <Row>
              <Text bold>{moment(station.departureTime).format('DD.MM')}</Text>
              <Text> {t('clockTime')} </Text>
              <Text bold>{moment(station.departureTime).format('HH:mm')}</Text>
            </Row>
            {loading && selectedHandlingStation === station.ocpCode ? (
              <CardSpinnerContainer>
                <LoadingIndicator size="custom" customSize={32} />
              </CardSpinnerContainer>
            ) : (
              <LinkButton
                onClick={() => {
                  setSelectedHandlingStation(station.ocpCode)
                  fetchCompositionsWithParams(station.departureTime, trainNumber, station.ocpCode)
                }}
              >
                <ArrowRight />
              </LinkButton>
            )}
          </HandlingStationCard>
        )
      })}
      {handlingStationsError && <ManualSearchInfo>{t('noHandlingStationsFound')}</ManualSearchInfo>}
      {showManualSearch && (
        <div onClick={() => setActiveForm('manual')}>
          <SearchTitle>{t('searchCompositionsWithStation')}</SearchTitle>
          <UpperSearchContainer>
            <SearchBox
              onClick={() => setActiveField('manualTrainNumber')}
              disabled={manualDisabled}
              active={activeField === 'manualTrainNumber'}
            >
              <SearchHeader>{t('trainNumber')}</SearchHeader>
              <DisableableInput
                type="text"
                value={trainNumber}
                disabledColor={manualDisabled}
                onChange={(event) => setTrainNumber(event.target.value)}
              />
            </SearchBox>
          </UpperSearchContainer>
          <SearchContainer
            onKeyDown={
              validateManualSearch()
                ? (ev) => {
                    return (
                      ev.keyCode === 13 &&
                      manualCompositionSearch(departureDate, trainNumber, startStation)
                    )
                  }
                : undefined
            }
          >
            <SearchBox
              marginRight="8px"
              onClick={() => setActiveField('startStation')}
              disabled={manualDisabled}
              active={activeField === 'startStation'}
            >
              <SearchHeader>{t('startStation')}</SearchHeader>
              <DisableableInput
                type="text"
                value={startStation}
                disabledColor={manualDisabled}
                onChange={(event) => setStartStation(event.target.value)}
              />
            </SearchBox>
            <SearchBox
              marginLeft="8px"
              onClick={() => setActiveField('departureDate')}
              disabled={manualDisabled}
              active={activeField === 'departureDate'}
            >
              <SearchHeader>{t('startDay')}</SearchHeader>
              <DisableableInput
                type="text"
                readOnly
                value={moment(departureDate).format('L')}
                disabledColor={manualDisabled}
                onChange={() => null}
              />
            </SearchBox>
          </SearchContainer>
        </div>
      )}
      {departureDateActive && (
        <DateContainer>
          <DatePicker
            inline
            locale="fi"
            selected={new Date(moment(departureDate).toDate())}
            onChange={(value) => changeDepartureDate(value)}
          />
        </DateContainer>
      )}
      {showManualSearch &&
        (loading ? (
          <LoadingIndicator size="normal" padded={true} />
        ) : (
          <SearchButton
            t={t}
            validate={validateManualSearch}
            setError={setError}
            errorKey="fillAllMandatoryFields"
            halveTopMargin
            disabled={manualDisabled}
            onClick={() => manualCompositionSearch(journeyDate, trainNumber, startStation)}
            text="searchCompositions"
          />
        ))}
      {compositionsHistory && compositionsHistory.length > 0 && (
        <div>
          <SearchHistoryHeader>{t('lastSearch')}</SearchHistoryHeader>
          <div>
            {compositionsHistory.map((composition) => {
              const dateTimeString = moment(composition.date, 'YYYY-MM-DD', true).isValid()
                ? moment(composition.date).format('L')
                : moment(composition.date).format('L [klo] LT')
              return (
                <CardWithSpace
                  key={composition.trainNumber + composition.date}
                  t={t}
                  textKey={'compositionsHistoryResult'}
                  args={{
                    train: composition.trainNumber,
                    date: dateTimeString,
                    from: composition.station,
                  }}
                  onClick={() =>
                    fetchCompositionsWithParams(
                      composition.date,
                      composition.trainNumber,
                      composition.station
                    )
                  }
                  deleteAction={() =>
                    deleteCompositionFromHistory({
                      trainNumber: composition.trainNumber,
                      date: composition.date,
                      station: composition.route.origStation.stationAbbreviation,
                    })
                  }
                />
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    handlingStationsLoading: state.handlingStations.loading,
    handlingStations: state.handlingStations.stations,
    handlingStationsError: state.handlingStations.error,
  }
}

const mapDispatchToProps = (dispatch: TypedDispatch) => ({
  fetchHandlingStationsFromApi: (journeyDate: string, trainNumber: string) =>
    dispatch(fetchHandlingStations(journeyDate, trainNumber) as unknown as Action),
  clearHandlingStations: () => dispatch(clearHandlingStations()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompositionSearch)
