import styled from '@emotion/styled'

import { theme } from '../../Theme'

const SearchPageContainer = styled.div`
  ${theme.layout.flexCenterH};
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  flex-direction: row;
`

export default SearchPageContainer
