import { Box, Button, Collapse, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { differenceInMinutes, format } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '../..'
import ArrowDown from '../../icons/ArrowDown'
import ArrowUp from '../../icons/ArrowUp'
import { removeFromUpdatedIncidents } from '../../reducers/incidentsSlice'
import { DisruptionLevelIndicator } from './DisruptionLevelIndicator'
import MessageContainer from './IncidentMessage'
import { LiitoIncident } from './types'

const FoldingCard = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  background: theme.palette.background.default,
  padding: theme.spacing('smaller'),
  marginTop: theme.spacing('smaller'),
  marginBottom: theme.spacing('smaller'),
  color: isActive ? theme.palette.text.primary : theme.palette.text.disabled,
  ...(isActive && { boxShadow: '0 2px 6px 1px rgba(0, 0, 0, 0.1)' }),
}))

const IconContainer = styled('div')(() => ({
  display: 'flex',
  width: '10%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: '8px',
  paddingRight: '8px',
  marginLeft: '4px',
}))

type Props = {
  incident: LiitoIncident
}

const Incident = ({ incident }: Props) => {
  const { t } = useTranslation()

  const dispatch = useTypedDispatch()

  const [isOpen, setIsOpen] = useState(false)

  const isUpdated = useTypedSelector((state) =>
    state.incidents.updatedIncidentIds.includes(incident.id)
  )

  const handleChange = () => {
    setIsOpen((prev) => !prev)
    if (isUpdated && !isOpen) {
      dispatch(removeFromUpdatedIncidents(incident.id))
    }
  }

  const createdAt = new Date(incident.createdAt)
  const lastModifiedAt = new Date(incident.lastModifiedAt)

  const isActive = incident.status === 'ACTIVE'
  const internalMessages = incident.messages.filter((message) => message.messageType === 'INTERNAL')
  const customerMessages = incident.messages.filter((message) => message.messageType === 'CUSTOMER')

  return (
    <FoldingCard isActive={isActive}>
      <>
        <Box sx={(theme) => ({ ...theme.layout.flexRow, justifyContent: 'space-between' })}>
          {isUpdated ? (
            <Typography variant="subtitle2" sx={{ color: 'common.primaryGreen' }}>
              {incident.header}
            </Typography>
          ) : (
            <Typography variant="subtitle2" sx={{ fontWeight: 'normal' }}>
              {incident.header}
            </Typography>
          )}
          <IconContainer>
            {isOpen ? (
              <Button
                onClick={handleChange}
                sx={{ color: isActive ? 'common.promaryGreen' : 'text.disabled' }}
              >
                <ArrowUp iconSize="normal" />
              </Button>
            ) : (
              <Button
                onClick={handleChange}
                sx={{ color: isActive ? 'common.promaryGreen' : 'text.disabled' }}
              >
                <ArrowDown iconSize="normal" />
              </Button>
            )}
          </IconContainer>
        </Box>
        <Collapse in={isOpen}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {differenceInMinutes(lastModifiedAt, createdAt) > 1 && (
              <Typography variant="adminBarSmall" sx={{ color: 'grey.600' }}>
                {t('incidents.lastModifiedAt')}:{' '}
                {format(new Date(incident.lastModifiedAt), 'HH.mm d.M.yyyy')}
              </Typography>
            )}
            <Box sx={(theme) => ({ ...theme.layout.flexColumn, alignItems: 'flex-start' })}>
              {customerMessages.length > 0 && (
                <MessageContainer messages={customerMessages} messageType="CUSTOMER" />
              )}
              {internalMessages.length > 0 && (
                <MessageContainer messages={internalMessages} messageType="INTERNAL" />
              )}
            </Box>
          </Box>
        </Collapse>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {incident.disruptionLevel && (
            <DisruptionLevelIndicator disruptionLevel={incident.disruptionLevel} />
          )}
        </Box>
      </>
    </FoldingCard>
  )
}

export default Incident
