import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'

import { MAX_CAUSE_LABELS } from '../constants'
import { getResponsibleColor } from '../lib/responsibles'
import { getCauses } from '../Selectors'
import { color, theme } from '../Theme'
import { MaybeColor } from '../Theme'
import { Task } from '../types'
import { ColorProps } from '../types/App'
import { Delay } from '../types/Input'

interface LabelRowProps {
  left?: boolean
  isMobile?: boolean
}
interface TimeLabelProps {
  delayMinutes: number
  trainCategory: string
  estimate: boolean
  notDriving: boolean
}

interface DelayCutoff {
  highDelayCutOff: number
  mediumDelayCutOff: number
}

const LabelRow = styled.div<LabelRowProps>`
  ${theme.layout.flexRow};
  ${theme.layout.fluidWidth(theme.maxWidths.column)};
  color: ${color('black')};
  justify-content: flex-start;
  margin: 0px;
  margin-left: ${(p) => (p.left ? '8px' : '0px')};
`

export const DelayLabel = styled.div<ColorProps>`
  line-height: ${theme.font.smaller};
  font-size: ${theme.font.smaller};
  background-color: ${(p) => color(p.color)};
  color: ${color('black')};
  padding: 4px 8px 4px 8px;
  border: 1px solid;
  border-radius: 12px;
  border-color: ${color('grayDark')};
  margin-right: 1px;
`

export const TimeLabel = styled.div<TimeLabelProps>`
  color: ${(p) => color(getTimeLabelTextColor(p.delayMinutes, p.trainCategory))};
  line-height: ${theme.font.smaller};
  font-size: ${theme.font.smaller};
  background: ${(p) =>
    color(getTimeLabelColor(p.delayMinutes, p.trainCategory, p.estimate, p.notDriving))};
  padding: 4px 8px 4px 8px;
  border: 1px solid;
  border-radius: 12px;
  margin-right: 1px;
  margin-left: -3px;
`

export const getTimeLabelTextColor = (differenceInMinutes: number, trainCategory: string) => {
  const { highDelayCutOff, mediumDelayCutOff } = getDelayCutoff(trainCategory)
  return differenceInMinutes < highDelayCutOff && differenceInMinutes >= mediumDelayCutOff
    ? 'black'
    : 'white'
}

const getDelayCutoff = (trainCategory: string): DelayCutoff => {
  const highDelayCutOff =
    trainCategory === 'Long-distance' || trainCategory === 'Commuter' ? 15 : 30
  const mediumDelayCutOff =
    trainCategory === 'Commuter' ? 3 : trainCategory === 'Long-distance' ? 6 : 15
  return { highDelayCutOff, mediumDelayCutOff }
}

export const getTimeLabelColor = (
  differenceInMinutes: number,
  trainCategory: string,
  estimate: boolean,
  notDriving: boolean
): MaybeColor => {
  const { highDelayCutOff, mediumDelayCutOff } = getDelayCutoff(trainCategory)
  if (notDriving) {
    return 'grayDark'
  }

  if (differenceInMinutes >= highDelayCutOff) return estimate ? 'redTinted' : 'red'
  else if (differenceInMinutes >= mediumDelayCutOff)
    return estimate ? 'primaryYellowTinted' : 'primaryYellow'
  else if (differenceInMinutes >= 0) return estimate ? 'primaryGreenTinted' : 'primaryGreen'
  else return estimate ? 'primaryBlueTinted' : 'primaryBlue'
}

export const getTimeLabelText = (delayMinutes: number | null): string => {
  if (!delayMinutes) return '0'
  const delayMinutesText =
    delayMinutes === 0 ? '0' : delayMinutes > 0 ? `+${delayMinutes}` : `${delayMinutes}`
  return delayMinutesText
}

type Props = {
  t: TFunction
  task?: Task
  nightMode?: boolean
  delays: Array<Delay>
  delayMinutes: number
  trainCategory: string
  left?: boolean
  isMobile: boolean
  estimate: boolean
  notDriving: boolean
}

export const DelayLabelRow = ({
  delays,
  delayMinutes,
  trainCategory,
  left,
  estimate,
  notDriving,
  isMobile,
}: Props) => {
  const delayMinutesText = getTimeLabelText(delayMinutes)
  const maxCauseLabels = MAX_CAUSE_LABELS

  if (delays === undefined || delays.length === 0 || delayMinutes === undefined) {
    return <LabelRow />
  }

  return (
    <LabelRow left={left} isMobile>
      {delayMinutes !== undefined && !isMobile ? (
        <TimeLabel
          trainCategory={trainCategory}
          estimate={estimate}
          notDriving={notDriving}
          delayMinutes={delayMinutes}
        >
          {delayMinutesText}
        </TimeLabel>
      ) : null}
      {getCauses(delays, maxCauseLabels).map((cause, i) =>
        cause ? (
          <DelayLabel key={i} color={getResponsibleColor(cause.categoryCode)}>
            {cause.categoryCode}
          </DelayLabel>
        ) : null
      )}
    </LabelRow>
  )
}
