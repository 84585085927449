import { useEffect } from 'react'
import { Action } from 'redux'

import { useTypedDispatch, useTypedSelector } from '../..'
import { fetchSmDisruptionsAction } from '../../actions/sm-disruptions'
import {
  dismissSmDisruptionsNotification,
  selectSmDisruptionNotification,
} from '../../reducers/smDisruptionSlice'
import { SmDisruptionNotification } from './SmDisruptionNotification'

type Props = {
  fetchHistory?: boolean
  closable?: boolean
}

export const SmDisruptionNotificationContainer = ({
  fetchHistory = false,
  closable = true,
}: Props) => {
  const dispatch = useTypedDispatch()

  const activeMessage = useTypedSelector(selectSmDisruptionNotification)

  useEffect(() => {
    if (fetchHistory) {
      dispatch(fetchSmDisruptionsAction())
    }
  }, [dispatch, fetchHistory])

  const dismissSmDisruptions = () => {
    dispatch(dismissSmDisruptionsNotification() as unknown as Action)
  }

  return (
    <>
      {!!activeMessage && (
        <SmDisruptionNotification
          title={activeMessage.title}
          body={activeMessage.body}
          onClose={closable ? dismissSmDisruptions : undefined}
        />
      )}
    </>
  )
}
