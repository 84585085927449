import { Router } from 'found'
import { TFunction } from 'i18next'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import {
  ChangeViewFunction,
  deleteCompositionFromHistory,
  fetchCompositions,
  getCompositionSearchHistory,
} from '../../actions/api'
import CompositionSearch from '../../components/compositions/search/CompositionSearch'
import ErrorText from '../../components/ErrorText'
import Page from '../../components/page/Page'
import SearchPageContainer from '../../components/search/SearchPageContainer'
import SearchPageContent from '../../components/search/SearchPageContent'
import moment from '../../lib/moment-fi'
import { Action, AppState, Compositions, Dispatch } from '../../types'
import { SearchDeleteParams } from '../../types/Input'

type Props = {
  t: TFunction
  router: Router
  loadingCompositions: boolean
  compositionsError: string
  compositionsHistory: Array<Compositions>
  fetchCompositions: (
    date: string,
    trainNumber: string,
    station: string,
    changeView: ChangeViewFunction
  ) => Action
  getCompositionSearchHistory: () => void
  compositionFromHistory: (composition: Compositions, changeView: ChangeViewFunction) => Action
  deleteCompositionFromHistory: (searchDeleteParams: SearchDeleteParams) => Action
}

export type State = {
  date: string
  dateActive?: boolean
  error?: string
  startStation: string
  startStationActive?: boolean
  trainNumberActive?: boolean
  trainNumber: string
}

class CompositionSearchPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      trainNumber: '',
      date: moment().format('DD.MM.YYYY'),
      startStation: '',
      error: undefined,
    }
  }

  componentDidMount() {
    this.props.getCompositionSearchHistory()
  }

  changeValue(obj: State) {
    this.setState(obj)
  }

  fetchCompositions() {
    this.fetchCompositionsWithParams(
      moment(this.state.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      this.state.trainNumber,
      this.state.startStation
    )
  }

  fetchCompositionsWithParams(date: string, trainNumber: string, station: string) {
    this.props.fetchCompositions(date, trainNumber, station, () =>
      this.props.router.push(`/composition/${date}/${station}/${trainNumber}`)
    )
  }

  render() {
    const {
      t,
      router,
      loadingCompositions,
      compositionsError,
      compositionsHistory,
      deleteCompositionFromHistory,
    }: Props = this.props

    // set error from sub pages if needed
    if (this.state.error !== t(compositionsError))
      this.changeValue({ ...this.state, error: t(compositionsError) })

    return (
      <Page overflowVisible>
        <SearchPageContainer>
          {this.state.error && <ErrorText>{this.state.error}</ErrorText>}
        </SearchPageContainer>
        <SearchPageContainer>
          <SearchPageContent>
            <CompositionSearch
              fetchCompositions={() => this.fetchCompositions()}
              fetchCompositionsWithParams={(date, trainNumber, station) =>
                this.fetchCompositionsWithParams(date, trainNumber, station)
              }
              compositionsHistory={compositionsHistory}
              deleteCompositionFromHistory={deleteCompositionFromHistory}
              loading={loadingCompositions}
              router={router}
              setError={(error) => this.setState({ error })}
              state={this.state}
              t={t}
            />
          </SearchPageContent>
        </SearchPageContainer>
      </Page>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const loadingCompositions = state.compositions.loading
  const compositionsError = state.compositions.error
  const compositionsHistory = state.compositions.history

  return {
    loadingCompositions,
    compositionsError,
    compositionsHistory,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCompositions: (
    date: string,
    trainNumber: string,
    station: string,
    changeView?: ChangeViewFunction
  ) => dispatch(fetchCompositions(date, trainNumber, station, changeView) as unknown as Action),
  getCompositionSearchHistory: () => dispatch(getCompositionSearchHistory() as unknown as Action),
  deleteCompositionFromHistory: (params: SearchDeleteParams) =>
    dispatch(deleteCompositionFromHistory(params) as unknown as Action),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CompositionSearchPage))
