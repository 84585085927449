import NotificationBar, { ColorScheme } from '../notification/NotificationBar'

const red: ColorScheme = { day: 'red', night: 'red' }
const white: ColorScheme = { day: 'white', night: 'white' }

type Props = {
  title: string
  body: string
  onClose?: () => void
}

export const SmDisruptionNotification = ({ title, body, onClose }: Props) => {
  return (
    <NotificationBar
      title={title}
      body={body}
      colorOverrides={{
        Bar: { background: red, border: white },
        Circle: { color: white, border: white },
        Text: { color: white },
        CloseButtonText: { color: white },
      }}
      onClose={onClose}
    />
  )
}
