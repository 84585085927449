import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import {
  ChangeViewFunction,
  fetchShiftInformation,
  fetchShifts,
  loadSearchedShiftFromHistory,
} from '../actions/api'
import CalendarShift from '../components/calendar/CalendarShift'
import HeaderTitle from '../components/header/HeaderTitle'
import LoadingIndicator from '../components/LoadingIndicator'
import {
  Container,
  Content,
  Footer,
  FooterText,
  HeaderText,
  PageHeader,
  TitleText,
} from '../components/page/PageComponents'
import ShiftContainer from '../components/shift/ShiftContainer'
import { MOMENT_SHIFT_DATE_FORMAT } from '../constants'
import ArrowLeft from '../icons/ArrowLeft'
import moment from '../lib/moment-fi'
import { isMobileSelector, nightModeSelector, nowSelector } from '../Selectors'
import { getColor } from '../Theme'
import { Action, AppState, Dispatch, Moment, SearchedShift, Shift } from '../types'
import { MatchProps } from '../types/App'

const BackIcon = styled.div`
  align-content: start;
  display: flex;
  padding-left: 8px;
  color: ${(p) => getColor(p.theme, ['primaryBlue'], ['white'])};
`

type State = {
  shiftSelected: boolean
}

type Props = {
  t: TFunction
  router: Router
  loading: boolean
  error: string
  now: Moment
  isMobile: boolean
  shiftSearch: SearchedShift
  selected: Shift
  nightMode: boolean
  fetchShifts: (
    date: string,
    trainNumber: string,
    startStation: string,
    shiftId: string,
    changeView?: any
  ) => Action
  fetchShiftInformation: (shift: string, changeView?: ChangeViewFunction) => Action
  loadSearchedShiftFromHistory: (shiftId: string, date: string) => Action
  params: unknown
  match: MatchProps
}

class ShiftInstructions extends Component<Props, State> {
  componentDidMount() {
    const pathParameters = this.props.match.params[0].split('/')
    if (!this.props.shiftSearch && !this.props.selected) {
      if (pathParameters.includes('cached')) {
        this.props.loadSearchedShiftFromHistory(
          pathParameters[0],
          moment(pathParameters[1], 'DD.MM.YYYY').format('YYYY-MM-DD')
        )
      } else if (pathParameters.length === 1) {
        const params = pathParameters[1].split('&')
        const trainNumber = params.find((param) => param.startsWith('trainNumber=')).split('=')[1]
        const date = params.find((param) => param.startsWith('date=')).split('=')[1]
        const startStation = params.find((param) => param.startsWith('startStation=')).split('=')[1]
        this.props.fetchShifts(date, trainNumber, startStation, '')
      } else {
        this.props.fetchShiftInformation(pathParameters[0])
      }
    }
  }

  back() {
    const pathParameters = this.props.match.params[0].split('/')
    if (pathParameters.includes('cached') || pathParameters.length === 1) {
      window.history.back()
    } else if (!this.props.shiftSearch) {
      this.props.router.push('/search/shifts')
    } else {
      window.history.back()
    }
  }

  fetchInfo(shiftId: string, date: string, router: Router): Action {
    return this.props.fetchShiftInformation(shiftId, () =>
      router.push(
        `/shiftInstructions/${shiftId}/${moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')}`
      )
    )
  }

  render() {
    const { t, router, loading, error, now, isMobile, shiftSearch, selected, match }: Props =
      this.props

    const trainNumber = shiftSearch && shiftSearch.trainNumber ? shiftSearch.trainNumber : ''
    const ocp = shiftSearch && shiftSearch.ocp ? shiftSearch.ocp.toUpperCase() : ''
    const date = shiftSearch && shiftSearch.date ? shiftSearch.date : ''
    const parsedDate = date !== '' ? moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''
    const shiftId = shiftSearch && shiftSearch.shiftId ? shiftSearch.shiftId : ''

    const header = (shiftSearch: SearchedShift) => {
      if (!shiftSearch) return `${t('shiftId')} ${shiftId}, ${t('date')} ${parsedDate}`

      return shiftSearch.shiftId
        ? `${t('shiftId')} ${shiftId}, ${t('date')} ${parsedDate}`
        : `${t('trainNumber')} ${trainNumber}, ${t('startStation')} ${ocp}, ${t(
            'date'
          )} ${parsedDate}`
    }

    const shifts: Array<Shift> = shiftSearch ? shiftSearch.shifts : []

    return (
      <Container>
        <PageHeader onClick={() => this.back()} height="64px">
          <BackIcon>
            <ArrowLeft iconSize="normal" />
          </BackIcon>
          <HeaderText>{t('shiftSearchResults')}</HeaderText>
        </PageHeader>
        <Content>
          {error ? (
            <div>{t('shiftSearchError')}</div>
          ) : loading || (!selected && !shiftSearch) ? (
            <LoadingIndicator size="normal" padded={true} />
          ) : shifts.length >= 1 && match.params[0].split('/').length === 1 ? (
            <div>
              <TitleText>{header(shiftSearch)}</TitleText>
              {shifts.map((shift) => (
                <CalendarShift
                  key={shift.id}
                  shift={shift}
                  search={() => this.fetchInfo(shift.id, date, router)}
                />
              ))}
            </div>
          ) : (
            <div>
              <HeaderTitle
                title={t(`shiftNumber`, { number: selected.shiftId })}
                subtitle={moment(selected.startDateTime).format(MOMENT_SHIFT_DATE_FORMAT)}
              />
              <ShiftContainer
                now={now}
                shift={selected}
                isMobile={isMobile}
                reload={() => null}
                viewOnly={true}
                router={router}
              />
            </div>
          )}
        </Content>
        <Footer onClick={() => this.back()}>
          <BackIcon>
            <ArrowLeft iconSize="normal" stroke="white" />
          </BackIcon>
          <FooterText>{t('back')}</FooterText>
        </Footer>
      </Container>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const loading = state.searchedShifts.loading
  const error = state.searchedShifts.error

  const now = nowSelector(state)
  const isMobile = isMobileSelector(state) as boolean

  const shiftSearch = state.searchedShifts.list
  const selected = state.searchedShifts.selected
  return {
    loading,
    error,
    now,
    isMobile,
    shiftSearch,
    selected,
    nightMode: nightModeSelector(state) as boolean,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchShifts: (
    date: string,
    trainNumber: string,
    startStation: string,
    shiftId: string,
    changeView?: ChangeViewFunction
  ) =>
    dispatch(
      fetchShifts(date, trainNumber, startStation, shiftId, changeView) as unknown as Action
    ),
  fetchShiftInformation: (shift: string, changeView?: ChangeViewFunction) =>
    dispatch(fetchShiftInformation(shift, changeView) as unknown as Action),
  loadSearchedShiftFromHistory: (shiftId: string, date: string) =>
    dispatch(loadSearchedShiftFromHistory(shiftId, date) as unknown as Action),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ShiftInstructions))
