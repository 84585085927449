import styled from '@emotion/styled'
import { Alert } from '@mui/material'
import { Link } from 'found'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '..'
import { fetchEquipmentsAction } from '../actions/defect-common'
import {
  fetchClosedDefectsAction,
  fetchMoreClosedDefectsAction,
  fetchMoreOpenDefectsAction,
  fetchOpenDefectsAction,
} from '../actions/defects/index'
import Button from '../components/button/Button'
import { FormInputAutocomplete } from '../components/create-defect/input/FormInputAutocomplete'
import { Status } from '../components/create-defect/types'
import DefectsList from '../components/defects/DefectsList'
import LoadingIndicator from '../components/LoadingIndicator'
import Page from '../components/page/Page'
import { Container } from '../components/page/PageComponents'
import SearchPageHeader from '../components/search/SearchPageHeader'
import Text from '../components/Text'
import { createEquipmentDropdownOption } from '../lib/createDefectFormUtils'
import { setPreSelectedSerialNumber } from '../reducers/createDefectSlice'
import { resetDefects } from '../reducers/defectsSlice'
import { openDefectsSorted } from '../reducers/defectsSlice'
import { color, getColor, MaybeColor, theme } from '../Theme'
import { BoldProps } from '../types/App'
import { Defect } from '../types/Input'

const PaddedContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 10px;
  margin-bottom: 16px;
`

interface ActionButtonProps {
  width?: string
  color?: MaybeColor
  disabled?: boolean
  marginBottom?: string
  light?: boolean
  smallText?: boolean
}

const ActionLink = styled(Link)<ActionButtonProps>`
  ${theme.layout.flexRow};
  ${theme.text('small', 'decorative', 'bold')};
  ${theme.spacing.ends('smallest')};
  text-decoration: none;
  justify-content: center;
  background: ${(p) => color(p.color, 'primaryBlue')};
  border: none;
  color: ${color('white')};
  cursor: pointer;
  height: 100%;
  text-align: center;
  width: ${(p) => p.width ?? '50%'};
  height: 56px;
  margin-bottom: 50px;
  border-radius: ${theme.borderRadius.button};
  text-transform: uppercase;
`

const ActionButton = styled(Button)<ActionButtonProps>`
  width: ${(p) => p.width ?? '50%'};
  height: 56px;
  margin-bottom: ${(p) => p.marginBottom ?? '0'};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  background: ${(p) => {
    if (p.light) return 'rgba(36, 121, 179, 0.5)'
    if (p.disabled) return color('grayDark')
    if (p.color) return color(p.color)
    return color('primaryBlue')
  }};
  text-transform: ${(p) => (p.smallText ? 'none' : 'uppercase')};
`

const SubHeaderText = styled(Text)`
  text-transform: uppercase;
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayPink'])};
`

const BoldText = styled.div<BoldProps>`
  ${theme.text('normal')};
  font-weight: bold;
  margin-top: 12px;
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayPink'])};
`

const DefectsPage = () => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()

  const [installationGroup, setInstallationGroup] = useState('')
  const [subHeaderOpen, setSubHeaderOpen] = useState('')
  const [subHeaderClosed, setSubHeaderClosed] = useState('')

  const fetchOpenDefectsStatus = useTypedSelector((state) => state.defects.openStatus)
  const fetchClosedDefectsStatus = useTypedSelector((state) => state.defects.closedStatus)
  const fetchMoreOpenDefectsStatus = useTypedSelector((state) => state.defects.moreOpenStatus)
  const fetchMoreClosedDefectsStatus = useTypedSelector((state) => state.defects.moreClosedStatus)
  const openDefects = useTypedSelector(openDefectsSorted)
  const closedDefects = useTypedSelector((state) => state.defects.closedDefects)
  const openToken = useTypedSelector((state) => state.defects.openToken)
  const closedToken = useTypedSelector((state) => state.defects.closedToken)
  const nightMode = useTypedSelector((state) => state.system.nightMode)
  const equipments = useTypedSelector((state) => state.defectCommonData.equipments)

  useEffect(() => {
    dispatch(fetchEquipmentsAction(null))
  }, [dispatch])

  const triggerFetch = (status: Status) => {
    const fetchFunc = status === 'open' ? fetchOpenDefectsAction : fetchClosedDefectsAction
    const setSubHeaderFunc = status === 'open' ? setSubHeaderOpen : setSubHeaderClosed

    setSubHeaderClosed('')
    if (status === 'open') {
      setSubHeaderOpen('')
      dispatch(resetDefects())
    }
    if (installationGroup?.length > 0) {
      dispatch(setPreSelectedSerialNumber(installationGroup))
      dispatch(fetchFunc({ installationGroups: installationGroup }))
      setSubHeaderFunc(
        t(`defect.list.header.${status}`, {
          installationGroup,
          itemId: equipments?.data?.find((e) => e.installationGroup === installationGroup)?.itemId,
        })
      )
    }
  }

  const formMethods = useForm<{ installationGroups: string }>({
    defaultValues: {
      installationGroups: '',
    },
    mode: 'onTouched',
  })

  const generateStatusPart = (status: Status, defects: Array<Defect>) => {
    const actionStatus = status === 'open' ? fetchOpenDefectsStatus : fetchClosedDefectsStatus

    switch (actionStatus) {
      case 'failed':
        return (
          <Alert sx={{ marginTop: '5px' }} variant="filled" severity="error">
            {t('errors.defects.failed')}
          </Alert>
        )
      case 'loading':
        return <LoadingIndicator size="normal" padded={true} />
      case 'succeeded':
        return !defects.length ? (
          <BoldText style={{ marginBottom: '20px' }}>
            {t(`defect.list.noDefects.${status}`)}
          </BoldText>
        ) : (
          <></>
        )
      default:
        return <></>
    }
  }

  const generateDefectList = (status: Status) => {
    const subHeader = status === 'open' ? subHeaderOpen : subHeaderClosed
    const defects = status === 'open' ? openDefects : closedDefects
    const showMoreButton =
      (status === 'open' && !!openToken) || (status === 'closed' && !!closedToken)

    return (
      <>
        <SubHeaderText>{subHeader}</SubHeaderText>
        {generateStatusPart(status, defects)}
        {defects.length > 0 && (
          <DefectsList defects={defects} t={t} nightMode={nightMode} status={status} />
        )}
        {showMoreButton && (
          <ActionButton
            width="100%"
            smallText
            marginBottom="10px"
            disabled={
              status === 'open'
                ? fetchMoreOpenDefectsStatus === 'loading'
                : fetchMoreClosedDefectsStatus === 'loading'
            }
            onClick={() => {
              const fetchFunc =
                status === 'open' ? fetchMoreOpenDefectsAction : fetchMoreClosedDefectsAction
              dispatch(
                fetchFunc({
                  installationGroups: installationGroup,
                  token: status === 'open' ? openToken : closedToken,
                })
              )
            }}
          >
            {t(`defect.list.showMore.${status}`)}
          </ActionButton>
        )}
      </>
    )
  }

  return (
    <Page overflowVisible={true}>
      <Container>
        <SearchPageHeader name="defect" text={t('defect.list.title')} />
        <PaddedContainer>
          {equipments?.status === 'failed' && (
            <Alert sx={{ marginTop: '10px' }} variant="filled" severity="error">
              {t('errors.defects.equipments')}
            </Alert>
          )}
          <FormProvider {...formMethods}>
            <InputContainer>
              <FormInputAutocomplete
                name="installationGroups"
                label={t('defect.filter.installationGroup')}
                options={equipments?.data?.map((o) => createEquipmentDropdownOption(o)) ?? []}
                customOnChange={(value) => {
                  if (value) setInstallationGroup(value)
                }}
                status={equipments.status}
              />
              <ActionButton
                disabled={!installationGroup}
                onClick={() => {
                  triggerFetch('open')
                }}
              >
                {t('menu.search')}
              </ActionButton>
            </InputContainer>
          </FormProvider>
          {installationGroup && subHeaderOpen && (
            <>
              {generateDefectList('open')}
              {generateDefectList('closed')}
              {!subHeaderClosed && (
                <ActionButton
                  light
                  width="100%"
                  marginBottom="10px"
                  onClick={() => triggerFetch('closed')}
                >
                  {t('defect.showClosed')}
                </ActionButton>
              )}
              <ActionLink
                width="100%"
                to={{ pathname: '/create-defect', state: { from: window.location.pathname } }}
              >
                {t('defect.createNew')}
              </ActionLink>
            </>
          )}
        </PaddedContainer>
      </Container>
    </Page>
  )
}

export default DefectsPage
