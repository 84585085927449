import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'

import { getColor, theme } from '../../Theme'
import { Task, TaskDetailsInput } from '../../types'
import { RestaurantServices } from './RestaurantServices'
import { TaskDetailsTrains } from './TaskDetailsTrains'

interface TextDetailsContainerProps {
  type?: string
}

export const Text = styled.div<TextDetailsContainerProps>`
  ${theme.text('mediumSmall')};
  text-align: ${(p) => (p.type && p.type === 'to' ? 'right' : 'left')};
`

const DetailsContainer = styled.div<TextDetailsContainerProps>`
  ${theme.layout.flexColumn};
  align-items: ${(p) => (p.type === 'from' ? 'flex-start' : 'flex-end')};
  flex-grow: 1;
  flex-basis: 50%;
  color: ${(p) => getColor(p.theme, ['black'], ['grayPink'])};
`

const PaddedBottom = styled.div`
  ${theme.spacing.bottom('small')}
`

const BoldText = styled.div`
  ${theme.text('mediumSmall', 'content', 'bold')};
`

type Props = {
  t: TFunction
  details: TaskDetailsInput
  task: Task
}

const TaskDetails = ({ t, details, task }: Props) => {
  const { type, station, location, lockingInformation } = details
  return (
    <DetailsContainer type={type}>
      {station && (
        <PaddedBottom>
          <BoldText>{station}</BoldText>
        </PaddedBottom>
      )}
      <RestaurantServices task={task} type={type} />
      <TaskDetailsTrains details={details} t={t} task={task} />
      {location && <Text type={type}>{location}</Text>}
      {lockingInformation && <Text>{`${t('lockingInformation')}: ${lockingInformation}`}</Text>}
    </DetailsContainer>
  )
}

export default TaskDetails
