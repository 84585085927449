import { TFunction } from 'i18next'
import moment from 'moment-timezone'
import React from 'react'
import { connect } from 'react-redux'

import { sendCrewNoticeAck } from '../../actions/api'
import { Action, AppState, CrewNotice, CrewNoticeAck, Dispatch, Moment } from '../../types'
import DynamicNotification from '../notification/DynamicNotification'
import NotificationBar from '../notification/NotificationBar'

type Props = {
  t: TFunction
  crewNotice: CrewNotice
  sendAck: (arg0: string, crewNoticeAck: CrewNoticeAck, timestamp: Moment) => void
  loading: boolean
  dynamic?: boolean
  error: unknown
  margin?: string
  marginTop?: string
  marginBottom?: string
  marginRight?: string
  marginLeft?: string
  borderTop?: boolean
}

const CrewNoticeNotification = ({
  t,
  crewNotice,
  sendAck,
  loading,
  dynamic,
  error,
  margin,
  marginTop,
  marginBottom,
  marginRight,
  marginLeft,
  borderTop,
}: Props) => {
  const currentTime = moment()
  !!crewNotice &&
    !crewNotice.ack &&
    !loading &&
    sendAck(crewNotice.crewNoticeId, 'SEEN', currentTime)
  const isAcknowledged = crewNotice.ack && crewNotice.ack === 'ACKNOWLEDGED'

  return dynamic ? (
    <DynamicNotification
      text={crewNotice.content}
      /* eslint-disable @typescript-eslint/no-empty-function */
      closeHandler={() => {}}
      sentAt={crewNotice.sentAt}
      actionText={t('acknowledge')}
      actionHandler={() => {
        sendAck(crewNotice.crewNoticeId, 'ACKNOWLEDGED', currentTime)
      }}
      loading={loading}
      error={error}
    />
  ) : (
    <NotificationBar
      markdown={crewNotice.content}
      action={() => {
        sendAck(crewNotice.crewNoticeId, 'ACKNOWLEDGED', currentTime)
      }}
      actionText={t('acknowledge')}
      loading={loading}
      error={error}
      sentAt={crewNotice.sentAt}
      isAcknowledged={isAcknowledged}
      doneText={
        crewNotice.eventAt && isAcknowledged
          ? `${t('acknowledged')} ${moment(crewNotice.eventAt).format('H:mm')}`
          : undefined
      }
      margin={margin}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginRight={marginRight}
      marginLeft={marginLeft}
      borderTop={borderTop}
    />
  )
}

type PropsIn = {
  crewNotice: CrewNotice
}

const mapStateToProps = (state: AppState, { crewNotice }: PropsIn) => {
  const loading: boolean = crewNotice && crewNotice.loading ? crewNotice.loading : false
  const error = crewNotice && crewNotice.error ? crewNotice.error : ''

  return {
    loading,
    error,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendAck: (crewNoticeId: string, ack: CrewNoticeAck, eventAt: Moment) =>
    dispatch(sendCrewNoticeAck(crewNoticeId, ack, eventAt) as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(CrewNoticeNotification)
