import { createAsyncThunk } from '@reduxjs/toolkit'

import { apiGET } from '../../lib/api'
import { TrainEquipmentInfo } from '../../types/Input'

const fetchTrainEquipmentInfo = (trainEqInfoParams): Promise<TrainEquipmentInfo> => {
  const { trainNumber, fromStation, toStation, startDateTime, endDateTime } = trainEqInfoParams
  return apiGET(
    `trainEquipmentInfo?trainNumber=${trainNumber}&fromStation=${fromStation}&toStation=${toStation}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`
  )
}

type TrainEqInfoParams = {
  trainNumber: string
  fromStation: string
  toStation: string
  startDateTime: string
  endDateTime: string
}

type FetchTrainEqInfoError = {
  message: string
}

export const fetchTrainEquipmentInformationAction = createAsyncThunk<
  TrainEquipmentInfo,
  TrainEqInfoParams,
  { rejectValue: FetchTrainEqInfoError }
>('train-equipment-info/fetchTrainEqInfo', async (params: TrainEqInfoParams, thunkApi) => {
  try {
    return await fetchTrainEquipmentInfo(params)
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch train equipment information',
    })
  }
})
