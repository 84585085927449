import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { sendFeedback } from '../actions/api'
import {
  setFeedbackErrorMessage,
  setFeedbackMessage,
  toggleFeedbackEmphasis,
  toggleFeedbackError,
  toggleFeedbackRequestAnswer,
  updateFeedbackStars,
} from '../actions/feedback'
import ActionError from '../components/ActionError'
import Annotated from '../components/annotated/Annotated'
import { SendButton } from '../components/button/Button'
import ButtonLink from '../components/button/ButtonLink'
import Header from '../components/header/Header'
import StyledInput from '../components/Input'
import Label from '../components/Label'
import LoadingIndicator from '../components/LoadingIndicator'
import { FooterPage } from '../components/page/Page'
import Rating from '../components/rating/Rating'
import RatingLinks from '../components/rating/RatingLinks'
import SelectableRow from '../components/SelectableRow'
import Text from '../components/Text'
import VerticalLayout from '../components/VerticalLayout'
import { MOMENT_SHIFT_DATE_FORMAT, MOMENT_SHIFTLIST_DATE_FORMAT } from '../constants'
import moment from '../lib/moment-fi'
import { bindDispatch, ThunkFunction } from '../lib/thunk'
import { scheduleURL, shiftFeedbackURL, shiftListFeedbackURL, shiftURL, visitURL } from '../lib/url'
import {
  shiftFeedback,
  shiftListFeedback,
  shiftsAndRestDaysSortedByDateSelector,
} from '../Selectors'
import { defaultTextColor, getColor, theme } from '../Theme'
import {
  Action,
  AppState,
  Dispatch,
  Feedback,
  FeedbackEmphasis,
  FeedbackError,
  RestDay,
  Shift,
} from '../types'

const ErrorText = styled.div`
  ${theme.text('larger', 'decorative', 'bold')};
  color: ${defaultTextColor};
  margin: ${theme.spacing.sizes.huge} 0;
  max-width: 100%;
  text-align: center;
`

const ErrorTitle = styled(ErrorText)`
  ${theme.text('largest', 'decorative', 'bold')}
`

const Container = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  /* Add room for scroll input into view */
  margin-bottom: 50vh;
`

type FeedbackAnswerProps = {
  isMaxLength: boolean
  maxLength: number
}

const FeedbackAnswerInput = styled(StyledInput)<FeedbackAnswerProps>`
  &:focus,
  &:focus-visible {
    outline: ${(p) => (p.isMaxLength ? 'none' : 'red')};
    border-color: ${(p) =>
      p.isMaxLength
        ? getColor(p.theme, ['red'], ['red'])
        : getColor(p.theme, ['nightGray'], ['white'])};
    box-shadow: 0 0 0 1px
      ${(p) =>
        p.isMaxLength
          ? getColor(p.theme, ['red'], ['red'])
          : getColor(p.theme, ['nightGray'], ['white'])};
  }
`

const WarningText = styled(Text)`
  color: ${(p) => getColor(p.theme, ['red'], ['red'])};
`

const FooterContainer = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  height: 100%;
`

const Constrain = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.paddedContent)};
`

const Row = styled.div`
  ${theme.layout.flexRow};
  ${theme.spacing.all('large')};
  background: ${(p) => getColor(p.theme, ['white'], ['nightBlack'])};
  color: ${defaultTextColor};
`

const shiftEmphasisReasons = [
  {
    title: 'rest',
    value: 'lepo',
  },
  {
    title: 'duration',
    value: 'pituus',
  },
  {
    title: 'tasks',
    value: 'sisalto',
  },
  {
    title: 'shiftList',
    value: 'sijoittuminen',
  },
  {
    title: 'startDateTime',
    value: 'alkamisaika',
  },
  {
    title: 'endTime',
    value: 'paattymisaika',
  },
]

const scheduleEmphasisReasons = [
  {
    title: 'scheduleRotation',
    value: 'kiertosuunta',
  },
  {
    title: 'amountOfFreeTime',
    value: 'vapaanmaara',
  },
  {
    title: 'freeTimeRequests',
    value: 'vapaatoiveet',
  },
  {
    title: 'pacing',
    value: 'rytmitys',
  },
  {
    title: 'fairness',
    value: 'tasapuolisuus',
  },
]

const shiftErrorReasons = [
  {
    title: 'startDateTime',
    value: 'aloitusaika',
  },
  {
    title: 'endDateTime',
    value: 'lopetusaika',
  },
  /*{ // Missing in eSäLLi?
    title: 'extraTasks',
    value: 'ylimaarainentehtava'
  },*/
  {
    title: 'wrongTaskTimes',
    value: 'tehtavanajatvaarin',
  },
  {
    title: 'missingTasks',
    value: 'tehtavapuuttuu',
  },
]

const scheduleErrorReasons = [
  {
    title: 'scheduleContinuity',
    value: 'jaksonloppu',
  },
  {
    title: 'tooShortBreaks',
    value: 'lyhytvali',
  },
  {
    title: 'missingShift',
    value: 'tyovuoropuuttuu',
  },
  {
    title: 'notQualified',
    value: 'eipatevyytta',
  },
  {
    title: 'invalidAbsense',
    value: 'poissaolovaarin',
  },
]

type BaseProps = {
  t: TFunction
  stars: number
  shift: Shift | RestDay | null
  shiftsLoading: boolean
  feedback: Feedback
  toggleError: (feedbackError: FeedbackError, feedback: Feedback) => void
  toggleEmphasis: (feedbackEmphasis: FeedbackEmphasis, feedback: Feedback) => void
  toggleRequestAnswer: (feedback: Feedback) => void
  setMessage: (arg0: string, feedback: Feedback) => void
  setErrorMessage: (arg0: string, feedback: Feedback) => void
  sendFeedback: (feedback: Feedback) => void
  closeFeedback: (arg0: string) => void
  changeStars: (stars: number, feedback: Feedback) => void
}

type Props =
  | (BaseProps & {
      scope: 'shift'
      shift: Shift | null
    })
  | (BaseProps & {
      scope: 'schedule'
      shift: RestDay | Shift | null
    })

/* eslint-disable @typescript-eslint/no-empty-function */
const noop = () => {}

const changeStarsUrl = (stars: number, feedback: Feedback) => {
  if (feedback.type === 'schedule') {
    return shiftListFeedbackURL(feedback.listId, stars)
  }
  return shiftFeedbackURL(feedback.shiftId, stars)
}

const FeedbackPage = ({
  t,
  stars,
  shift,
  feedback,
  shiftsLoading,
  toggleError,
  toggleEmphasis,
  toggleRequestAnswer,
  setMessage,
  setErrorMessage,
  sendFeedback,
  scope,
  closeFeedback,
  changeStars,
}: Props) => {
  if (!shift) {
    return (
      <FooterPage
        footer={
          <FooterContainer>
            <ButtonLink topOpen={false} bottomOpen={true} to={scheduleURL()}>
              {t('close')}
            </ButtonLink>
          </FooterContainer>
        }
        footerHeight={theme.maxHeights.footer}
      >
        {shiftsLoading ? (
          <LoadingIndicator padded={true} size="normal" />
        ) : (
          <ErrorTitle>{t('errors.general.notFound')}</ErrorTitle>
        )}
      </FooterPage>
    )
  }

  const isBad = stars < 3
  const targetsShift = scope === 'shift'
  const closeUrl = targetsShift ? shiftURL((shift as Shift).id) : scheduleURL()

  const sending = !feedback.id && feedback.loading
  const sent = !!feedback.id
  const unsent = !sent && !sending
  const disabled = sent || sending

  const textareaMaxLength = 512

  return (
    <FooterPage
      footer={
        <FooterContainer>
          {unsent ? (
            <SendButton
              sending={false}
              sent={false}
              title={t('sendFeedback')}
              onClick={() => sendFeedback(feedback)}
            />
          ) : undefined}
          {sending ? (
            <SendButton sending={true} sent={false} title={t('sendingFeedback')} onClick={noop} />
          ) : undefined}
          {sent ? (
            <SendButton
              sending={false}
              sent={true}
              title={t('close')}
              onClick={() => closeFeedback(closeUrl)}
            />
          ) : undefined}
        </FooterContainer>
      }
      footerHeight={theme.maxHeights.footer}
    >
      <Header
        border={true}
        title={
          targetsShift
            ? t(`shiftFeedback`, { number: (shift as Shift).shiftId })
            : t('shiftListFeedback')
        }
        links={{
          prev: { url: closeUrl, disabled: false },
          next: null,
          close: { url: closeUrl, disabled: false },
        }}
      >
        {targetsShift
          ? moment(shift.startDateTime).format(MOMENT_SHIFT_DATE_FORMAT)
          : `${moment(shift.listStartDate).format(
              MOMENT_SHIFTLIST_DATE_FORMAT
            )} – ${(shift.listEndDate
              ? moment(shift.listEndDate)
              : moment(shift.listStartDate).add(20, 'days')
            ).format(MOMENT_SHIFTLIST_DATE_FORMAT)}`}
      </Header>
      <Container>
        <Constrain>
          {feedback.error && unsent ? (
            <Row>
              <ActionError
                error={feedback.error}
                action={() => sendFeedback(feedback)}
                actionText="retry"
              />
            </Row>
          ) : undefined}
          <VerticalLayout>
            {disabled ? (
              <Rating stars={feedback.stars} />
            ) : (
              <RatingLinks
                isMobile={false}
                bg=""
                onClick={(stars) => changeStars(stars as number, feedback)}
                getUrl={(stars) => changeStarsUrl(stars, feedback)}
                stars={feedback.stars}
              />
            )}
            <Row key="question">
              <Label>{isBad ? t(`${scope}WhatWasBad`) : t(`${scope}WhatWasGood`)}</Label>
            </Row>
            {(scope === 'shift' ? shiftEmphasisReasons : scheduleEmphasisReasons).map(
              (reason, i) => (
                <SelectableRow
                  key={i}
                  disabled={disabled}
                  onClick={() => toggleEmphasis(reason.value as FeedbackEmphasis, feedback)}
                  title={t(`feedbackAnswer.${reason.title}`)}
                  selected={feedback.emphasis.indexOf(reason.value as FeedbackEmphasis) !== -1}
                />
              )
            )}
            <Row key="message">
              <Annotated title={t('feedbackAnswer.somethingElse')} horizontal={false}>
                <FeedbackAnswerInput
                  key="message"
                  rows={2}
                  value={feedback.message}
                  disabled={disabled}
                  maxLength={textareaMaxLength}
                  isMaxLength={feedback.message?.length === textareaMaxLength}
                  onChange={(value) => setMessage(value, feedback)}
                />
                {(feedback.message?.length ?? 0) === textareaMaxLength ? (
                  <WarningText>
                    {t('feedbackAnswer.textareaMaxLength', { maxLength: textareaMaxLength })}
                  </WarningText>
                ) : (
                  <></>
                )}
              </Annotated>
            </Row>
            {isBad ? (
              <Row key="questionErrors">
                <Label>{t('didShiftContainErrors')}</Label>
              </Row>
            ) : undefined}
            {isBad
              ? (scope === 'shift' ? shiftErrorReasons : scheduleErrorReasons).map((reason, i) => (
                  <SelectableRow
                    key={i}
                    disabled={disabled}
                    onClick={() => toggleError(reason.value as FeedbackError, feedback)}
                    title={t(`feedbackAnswer.${reason.title}`)}
                    selected={feedback.errors.indexOf(reason.value as FeedbackError) !== -1}
                  />
                ))
              : undefined}
            {isBad ? (
              <Row key="errorMessage">
                <Annotated title={t('feedbackAnswer.somethingElse')} horizontal={false}>
                  <FeedbackAnswerInput
                    key="error"
                    rows={2}
                    value={feedback.errorMessage}
                    disabled={disabled}
                    maxLength={textareaMaxLength}
                    isMaxLength={feedback.message?.length === textareaMaxLength}
                    onChange={(value) => setErrorMessage(value, feedback)}
                  />
                  {(feedback.message?.length ?? 0) === textareaMaxLength ? (
                    <WarningText>
                      {t('feedbackAnswer.textareaMaxLength', { maxLength: textareaMaxLength })}
                    </WarningText>
                  ) : (
                    <></>
                  )}
                </Annotated>
              </Row>
            ) : undefined}
            <SelectableRow
              key="needResponse"
              disabled={disabled}
              onClick={() => toggleRequestAnswer(feedback)}
              title={t('wantsResponse')}
              selected={feedback.requestAnswer}
            />
            {feedback.response ? (
              <Row key="response">
                <Annotated title={t('feedbackResponse')} horizontal={false}>
                  <Text>{feedback.response}</Text>
                </Annotated>
              </Row>
            ) : undefined}
          </VerticalLayout>
        </Constrain>
      </Container>
    </FooterPage>
  )
}

const mapStateToProps = (state: AppState) => {
  const scope: 'shift' | 'schedule' =
    state.found.match.location.pathname.indexOf('list-feedback') !== -1 ? 'schedule' : 'shift'
  const stars = parseInt(state.found.match.params.stars, 10) || 0
  const idFromUrl = '' + state.found.match.params.shiftId // listId or ll_id
  let feedback: Feedback | null = null
  let shift: Shift | null | RestDay = null
  if (scope === 'schedule') {
    const listId = decodeURIComponent(idFromUrl)
    shift = shiftsAndRestDaysSortedByDateSelector(state).find((s) => s.listId === listId)
    if (!shift) {
      console.error('Shift not found', listId)
    }
    feedback = shiftListFeedback(shift?.listId, state)
  } else {
    shift = state.shifts.byId[idFromUrl]
    feedback = shiftFeedback(shift?.id, state)
  }

  if (!feedback) {
    feedback = {
      id: '',
      ...(scope === 'shift' && shift && { shiftId: (shift as Shift).id }), // actually ll_id
      listId: shift ? shift.listId : '',
      type: scope,
      stars: stars,
      requestAnswer: false,
      emphasis: [],
      message: '',
      errors: [],
      errorMessage: '',
      response: '',
      respondedAt: null,
      loading: false,
      error: '',
    }
  }

  return {
    scope,
    shift,
    loading: state.shifts.loading,
    stars,
    feedback: { ...feedback, stars },
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleError: (reason: FeedbackError, feedback: Feedback) => {
    dispatch(toggleFeedbackError(reason, feedback))
  },
  toggleEmphasis: (reason: FeedbackEmphasis, feedback: Feedback) => {
    dispatch(toggleFeedbackEmphasis(reason, feedback))
  },
  setMessage: (message: string, feedback: Feedback) => {
    dispatch(setFeedbackMessage(message, feedback))
  },
  setErrorMessage: (errorMessage: string, feedback: Feedback) => {
    dispatch(setFeedbackErrorMessage(errorMessage, feedback))
  },
  toggleRequestAnswer: (feedback: Feedback) => {
    dispatch(toggleFeedbackRequestAnswer(feedback))
  },
  changeStars: (stars: number, feedback: Feedback) => {
    dispatch(updateFeedbackStars(stars, feedback))
  },
  closeFeedback: (url: string) => dispatch(visitURL(url) as unknown as Action),
  sendFeedback: bindDispatch(sendFeedback as ThunkFunction, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FeedbackPage))
