import { Router } from 'found'
import { TFunction } from 'i18next'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { fetchTimetable } from '../../actions/api'
import ErrorText from '../../components/ErrorText'
import Page from '../../components/page/Page'
import SearchPageContainer from '../../components/search/SearchPageContainer'
import SearchPageContent from '../../components/search/SearchPageContent'
import TimetableSearch from '../../components/timetable/TimetableSearch'
import moment from '../../lib/moment-fi'
import { Action, AppState, Dispatch } from '../../types'
import { MatchProps } from '../../types/App'
import { TimetableParams } from '../../types/Input'

type Props = {
  t: TFunction
  router: Router
  match: MatchProps
  loadingTimetable: boolean
  timetableError: string
  fetchTimetable: (arg0: boolean, array: Array<TimetableParams>) => void
}

export type State = {
  date: string
  dateActive?: boolean
  error?: string
  showCalendar?: boolean
  trainNumberActive?: boolean
  trainNumber: string
  isUsedForDriving: boolean
}

class TimetableSearchPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      trainNumber: '',
      date: moment().format('DD.MM.YYYY'),
      showCalendar: false,
      error: undefined,
      isUsedForDriving: false,
    }
  }

  changeTrainNumber(value: string) {
    const matchPositiveNumber = /^[1-9]\d*$/
    if ((this.state.trainNumber !== '' && value === '') || matchPositiveNumber.test(value)) {
      return this.setState({ trainNumber: value, error: undefined })
    }
    this.setState({ error: 'Junanumeron pitää olla positiivinen' })
  }

  changeDate(value: moment.MomentInput) {
    this.setState({
      date: moment(value).format('DD.MM.YYYY'),
      showCalendar: false,
      error: undefined,
    })
  }

  openCalendar(showCalendar: boolean) {
    this.setState({ showCalendar })
  }

  activateTrainNumber() {
    this.setState({ trainNumberActive: true, dateActive: false, showCalendar: false })
  }

  activateDate() {
    this.setState({ trainNumberActive: false, dateActive: true })
  }

  activateField(obj: State) {
    this.setState(obj)
  }

  changeValue(obj: State) {
    this.setState(obj)
  }

  render() {
    const { t, loadingTimetable, timetableError, fetchTimetable }: Props = this.props

    if (this.state.error !== t(timetableError))
      this.changeValue({ ...this.state, error: t(timetableError) })

    return (
      <Page overflowVisible>
        <SearchPageContainer>
          {this.state.error && <ErrorText>{this.state.error}</ErrorText>}
        </SearchPageContainer>
        <SearchPageContainer>
          <SearchPageContent>
            <TimetableSearch
              activateDate={() =>
                this.activateField({
                  ...this.state,
                  ...{
                    dateActive: true,
                    trainNumberActive: false,
                  },
                })
              }
              activateTrainNumber={() =>
                this.activateField({
                  ...this.state,
                  ...{
                    dateActive: false,
                    trainNumberActive: true,
                  },
                })
              }
              changeDate={(value) => this.changeDate(value)}
              changeTrainNumber={(value) => this.changeTrainNumber(value)}
              changeUseForDriving={(isUsedForDriving) =>
                this.changeValue({ ...this.state, ...{ isUsedForDriving } })
              }
              fetchTimetable={() =>
                fetchTimetable(this.state.isUsedForDriving, [
                  {
                    trainNumber: this.state.trainNumber,
                    timetableDate: moment(this.state.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                  },
                ])
              }
              loading={loadingTimetable}
              openCalendar={(bool) => this.openCalendar(bool)}
              setError={(error) => this.setState({ error })}
              state={this.state}
            />
          </SearchPageContent>
        </SearchPageContainer>
      </Page>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const loadingTimetable = state.timetable.loading
  const timetableError = state.timetable.error

  return {
    loadingTimetable,
    timetableError,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTimetable: (isUsedForDriving: boolean, timetableParams: TimetableParams[]) =>
    dispatch(fetchTimetable(isUsedForDriving, timetableParams) as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TimetableSearchPage))
