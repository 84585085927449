import { injectGlobal } from '@emotion/css'
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import { Router, withRouter } from 'found'
import React from 'react'
import { connect } from 'react-redux'

import packageJson from '../package.json'
import UpdatePopup from './components/update-popup/UpdatePopup'
import { APP_VERSION_STORAGE_KEY } from './constants'
import { isMaintenanceUser, isWithinMaintenanceUrls } from './lib/roleUtils'
import LoginPage from './pages/LoginPage'
import { nightModeSelector } from './Selectors'
import { getColor } from './Theme'
import { MuiTheme } from './theme/MuiTheme'
import { AppState, UserState } from './types'

interface Props {
  children: JSX.Element | string
  router: Router
}

interface AppProps {
  acting: boolean
}

interface StateProps {
  acting: boolean
  loggedIn: boolean
  nightMode: boolean
  newVersion: boolean
  user: UserState
  pathname: string
}

const App = styled.div<AppProps>`
  position: relative;
  height: 100vh;
  width: 100%;
  z-index: 0;
  overflow: visible;
  overflow-x: hidden;
  background: ${(p) => getColor(p.theme, ['grayBackground'], ['nightBackground'])};
`

injectGlobal`
  html, body {
    margin: 0;
    padding: 0;
  }
  body {
    font-family: sans-serif;
    font-size: 16px;
    line-height: 1.5em;
  }
`

const nightTheme = { nightMode: true }
const dayTheme = { nightMode: false }

// TODO: maybe refactor role management so that we don't need to maintain roles both in backend and frontend
const roles = [
  'admin',
  'commuter_driver',
  'commuter_conductor',
  'commuter_manager',
  'conductor',
  'driver',
  'logistics_driver',
  'waiter',
  'other',
  'read_admin',
  'maintenance',
]

const hasAnyRole = (user: UserState) => roles.some((role) => user[role])

const ThemedApp = ({
  acting,
  loggedIn,
  nightMode,
  newVersion,
  children,
  user,
  pathname,
  router,
}: Props & StateProps): JSX.Element => {
  const hasRole = hasAnyRole(user)

  const isMaintenance = isMaintenanceUser(user)
  const isWithinMaintenance = isWithinMaintenanceUrls(pathname)
  if (hasRole && isMaintenance && !isWithinMaintenance) {
    router.replace('/search/towings')
  }

  return (
    <MuiTheme>
      <EmotionThemeProvider theme={nightMode ? nightTheme : dayTheme}>
        <App acting={acting}>
          {loggedIn && hasRole ? (
            children
          ) : (
            <LoginPage errorTextKey={loggedIn ? 'noRolesFound' : undefined} />
          )}
          {loggedIn && hasRole && newVersion && <UpdatePopup />}
        </App>
      </EmotionThemeProvider>
    </MuiTheme>
  )
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    acting: state.user.number !== state.user.originalNumber,
    loggedIn: !!state.user.number,
    newVersion:
      state.system.newVersion ||
      packageJson.version !== localStorage.getItem(APP_VERSION_STORAGE_KEY),
    nightMode: nightModeSelector(state) as boolean,
    user: state.user,
    pathname: state.found.match.location.pathname,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
// TODO: fix this TS error
export default withRouter(connect(mapStateToProps)(ThemedApp))
