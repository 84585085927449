import { Router } from 'found'
import { TFunction } from 'i18next'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { searchPunctuality } from '../../actions/api'
import ErrorText from '../../components/ErrorText'
import Page from '../../components/page/Page'
import PunctualitySearch from '../../components/punctuality/PunctualitySearch'
import SearchPageContainer from '../../components/search/SearchPageContainer'
import SearchPageContent from '../../components/search/SearchPageContent'
import moment from '../../lib/moment-fi'
import { Action, AppState, Dispatch } from '../../types'

type Props = {
  t: TFunction
  router: Router
  loadingPunctuality: boolean
  punctualityError: string
  searchPunctuality: (arg0: string, arg1: string, func: () => unknown) => void
}

export type State = {
  date: string
  dateActive?: boolean
  error?: string
  showCalendar?: boolean
  trainNumberActive?: boolean
  trainNumber: string
}

class PunctualitySearchPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      trainNumber: '',
      date: moment().format('DD.MM.YYYY'),
      showCalendar: false,
      error: undefined,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    const { t, punctualityError } = this.props
    if (!punctualityError && Boolean(nextProps.punctualityError)) {
      this.setState({ error: t('punctualityError') })
    }
  }

  changeTrainNumber(value: string) {
    const matchPositiveNumber = /^[1-9]\d*$/
    if ((this.state.trainNumber !== '' && value === '') || matchPositiveNumber.test(value)) {
      return this.setState({ trainNumber: value, error: undefined })
    }
    this.setState({ error: 'Junanumeron pitää olla positiivinen' })
  }

  changeDate(value: moment.MomentInput) {
    this.setState({
      date: moment(value).format('DD.MM.YYYY'),
      showCalendar: false,
      error: undefined,
    })
  }

  openCalendar(showCalendar: boolean) {
    this.setState({ showCalendar })
  }

  activateTrainNumber() {
    this.setState({ trainNumberActive: true, dateActive: false, showCalendar: false })
  }

  activateDate() {
    this.setState({ trainNumberActive: false, dateActive: true })
  }

  activateField(obj: State) {
    this.setState(obj)
  }

  fetchPunctuality() {
    const { date, trainNumber } = this.state
    const { router } = this.props
    this.props.searchPunctuality(
      moment(this.state.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      trainNumber,
      () => router.push(`/punctuality/${date}/${trainNumber}`)
    )
  }

  render() {
    const { t, loadingPunctuality }: Props = this.props

    return (
      <Page overflowVisible>
        <SearchPageContainer>
          {this.state.error && <ErrorText>{this.state.error}</ErrorText>}
        </SearchPageContainer>
        <SearchPageContainer>
          <SearchPageContent>
            <PunctualitySearch
              activateDate={() =>
                this.activateField({
                  ...this.state,
                  ...{
                    dateActive: true,
                    trainNumberActive: false,
                  },
                })
              }
              activateTrainNumber={() =>
                this.activateField({
                  ...this.state,
                  ...{
                    dateActive: false,
                    trainNumberActive: true,
                  },
                })
              }
              changeDate={(value) => this.changeDate(value)}
              changeTrainNumber={(value) => this.changeTrainNumber(value)}
              fetchPunctuality={() => this.fetchPunctuality()}
              loading={loadingPunctuality}
              openCalendar={(bool) => this.openCalendar(bool)}
              setError={(error) => this.setState({ error })}
              state={this.state}
              t={t}
            />
          </SearchPageContent>
        </SearchPageContainer>
      </Page>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const loadingPunctuality = state.punctuality.loading
  const punctualityError = state.punctuality.error

  return {
    loadingPunctuality,
    punctualityError,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  searchPunctuality: (trainDate: string, trainNumber: string, changeView: (() => void) | null) =>
    dispatch(searchPunctuality(trainDate, trainNumber, changeView) as unknown as Action),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PunctualitySearchPage))
