import { Task, TrainEquipmentInfo } from '../types/Input'

export const generateEquipmentInfoKey = (task: Task | TrainEquipmentInfo) =>
  `${task.trainNumber}+${task.operatingDate}+${task.fromStation}+${task.toStation}`

type RestaurantEquipment = {
  count: number
  equipmentType: string
  wagonCode: string
  relatedTrainNumber: string
  timestamp: string
}

export const filterAndGroupTrainEquipmentInfo = (
  trainEquipmentInfo: TrainEquipmentInfo,
  type: string
) => {
  const filteredInfo =
    trainEquipmentInfo?.equipment
      .filter((eq) => (type === 'to' ? !!eq.toTrain : type === 'from' && !!eq.fromTrain))
      .map((eq) => ({
        count: 1,
        equipmentType: eq.type,
        wagonCode: eq.wagonCode,
        relatedTrainNumber: eq[`${type}Train`].trainNumber,
        timestamp: eq[`${type}Train`][`timestamp${type === 'to' ? 'Start' : 'End'}`],
      })) || []

  const groupedByTrainAndType = filteredInfo.reduce(
    (acc, equipment) => {
      if (equipment) {
        const trainKey = `${equipment.relatedTrainNumber}-${equipment.timestamp}`
        const wagonKey = `${equipment.equipmentType}-${equipment.wagonCode}`
        if (!acc[trainKey]) {
          acc[trainKey] = { [wagonKey]: equipment }
        } else {
          if (!acc[trainKey][wagonKey]) {
            acc[trainKey][wagonKey] = equipment
          } else {
            acc[trainKey][wagonKey].count += 1
          }
        }
      }
      return acc
    },
    {} as Record<string, Record<string, RestaurantEquipment>>
  )

  return groupedByTrainAndType
}
