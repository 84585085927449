import styled from '@emotion/styled'
import { Router } from 'found'
import React from 'react'

import { MAX_CAUSE_LABELS } from '../../constants'
import Document from '../../icons/Document'
import { commuterToTrainDetails, getVehicleCount, getVehicleNumbers } from '../../Selectors'
import { getColor, LAYOUT_LEFT_EDGE, theme } from '../../Theme'
import { Color, Contact, Shift, Task } from '../../types'
import DebugValuePanel from './DebugValuePanel'
import TaskRowInfo from './TaskRowInfo'

interface ContentProps {
  active: boolean
  border: boolean
  past: boolean
  even: boolean
}

interface InfoRowProps {
  active: boolean
  isMobile: boolean
}

const rowBgDay = ({ active, even }: { active: boolean; even: boolean }): Color => {
  if (active) return 'shallowGreen'
  if (even) return 'white'
  return 'grayBackground'
}

const rowBgNight = ({ active, even }: { active: boolean; even: boolean }): Color => {
  if (active) return 'darkGreen'
  if (even) return 'nightSeparator'
  return 'nightGray'
}

const stripeColor = ({ active, past }: { active: boolean; past: boolean }): Color => {
  if (past) return 'primaryGreenTinted'
  if (active) return 'primaryGreen'
  return 'primaryBlue'
}

const Content = styled.div<ContentProps>`
  background: ${(p) => getColor(p.theme, [rowBgDay(p)], [rowBgNight(p)])};
  border-left: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightBlack'])};
  border-right: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightBlack'])};
  ${(p) =>
    p.border ? `border-bottom: 1px solid ${getColor(p.theme, ['grayBlue'], ['nightBlack'])}` : ''};
  ${(p) =>
    (p.active || p.past) &&
    `border-left: 5px solid ${getColor(p.theme, [stripeColor(p)], [stripeColor(p)])};`};
`

const InfoRow = styled.div<InfoRowProps>`
  ${theme.layout.flexRow};
  ${theme.spacing.ends('smallest')};
  margin: 0 12px 0
    ${(p) =>
      p.isMobile ? `${LAYOUT_LEFT_EDGE - (p.active ? 6 : 1)}px` : `${LAYOUT_LEFT_EDGE + 130}px`};
  border-top: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightBlack'])};
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayPink'])};
`

const InfoText = styled.div`
  ${theme.text('small', 'content')}
  overflow-wrap: anywhere
`

const TaskRowContent = ({
  shift,
  active,
  task,
  taskInstruction,
  contacts,
  info,
  router,
  filteredLocs,
  commaSeparatedLocs,
  taskTitle,
  nightMode,
  delays,
  delayMinutes,
  even,
  past,
  bottomBorder,
  isMobile,
  totalCauses,
  open,
  isCommuterMealBreak,
  rowAction,
  debugEnabled,
}: PropsIn) => {
  const maxCauseLabels = MAX_CAUSE_LABELS
  const noIconInstructions = ['Juna', '']
  const showContent = (open || !past || shift.isCommuter) && !isCommuterMealBreak
  const toTrainDetails = shift.isCommuter && commuterToTrainDetails(task)
  const toTrains =
    toTrainDetails &&
    toTrainDetails.trains.map((tr) => `${tr.lineId || '-'} ${tr.trainNumber}`).join('/')

  const vehicleNumbers: string[] | undefined = shift.isCommuter
    ? getVehicleNumbers(task)
    : undefined
  const vehicleCount = getVehicleCount(task)
  const showVehicleCount = shift.isCommuter && vehicleCount > 1
  const vehicleCountString = showVehicleCount ? `${vehicleCount}` : ''

  const infoRowOnClick = shift.isCommuter
    ? rowAction
    : (e: { stopPropagation: () => any }) => e.stopPropagation()

  const taskRowInfoElement = (
    <TaskRowInfo
      open={open}
      shift={shift}
      active={active || past}
      isMobile={isMobile}
      task={task}
      vehicleNumbers={vehicleNumbers}
      contacts={contacts}
      info={info}
      router={router}
      locs={filteredLocs}
      commaSeparatedLocs={commaSeparatedLocs}
      taskTitle={taskTitle}
      nightMode={nightMode}
      even={even}
      drawDelays={totalCauses > maxCauseLabels && open}
      delayObject={{ delays, delayMinutes }}
    />
  )

  return showContent ? (
    <Content even={even} active={active} past={past} border={bottomBorder} onClick={infoRowOnClick}>
      {taskInstruction || shift.isCommuter ? (
        <InfoRow isMobile={isMobile} active={active || past}>
          {!noIconInstructions.includes(taskInstruction) ? (
            <Document iconSize="smallest" />
          ) : undefined}
          &nbsp;
          <InfoText>
            {taskInstruction}
            {taskInstruction && showVehicleCount ? '. ' : ''}
            {showVehicleCount ? `${vehicleCountString}` : ''}
            {shift.isCommuter && task.traction ? ` ${task.traction}` : ''}
            {toTrains ? ` ${String.fromCharCode(8658)} ${toTrains}` : ''}
          </InfoText>
        </InfoRow>
      ) : undefined}
      {open ? (
        debugEnabled ? (
          <DebugValuePanel label={'TaskRowInfo props'}>{taskRowInfoElement}</DebugValuePanel>
        ) : (
          taskRowInfoElement
        )
      ) : null}
    </Content>
  ) : null
}

TaskRowContent.displayName = 'TaskRowContent'

type PropsIn = {
  shift: Shift
  task: Task
  taskTitle: string
  taskInstruction: string
  even: boolean
  past: boolean
  active: boolean
  contacts: Array<Contact>
  info: Array<{
    title: string
    value: string
  }>
  open: boolean
  router: Router
  filteredLocs: Array<string>
  commaSeparatedLocs: string
  nightMode: boolean
  delays: Array<unknown>
  delayMinutes: number
  bottomBorder: boolean
  isMobile: boolean
  totalCauses: number
  isCommuterMealBreak: boolean
  rowAction: () => unknown
  debugEnabled: boolean
}

export default TaskRowContent
