import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import React from 'react'

import { Children, ShiftNotice } from '../../types'
// import { connect } from 'react-redux'
// import moment from 'moment-timezone'
import NotificationBar, { ColorScheme } from '../notification/NotificationBar'

const yellow: ColorScheme = { day: 'yellowDark', night: 'primaryYellow' }
const yellowBg: ColorScheme = { day: 'primaryYellow', night: 'yellowDark' }
const green: ColorScheme = { day: 'darkGreen', night: 'shallowGreen' }
const lightGreen: ColorScheme = { day: 'primaryGreenTinted', night: 'primaryGreen' }
const red: ColorScheme = { day: 'red', night: 'red' }
const white: ColorScheme = { day: 'white', night: 'white' }

const colors = {
  critical: {
    Bar: { background: red, border: yellow },
    Circle: { color: white, border: white },
    Text: { color: white },
  },
  warning: {
    Bar: { background: yellowBg, border: yellow },
    Circle: { color: yellow, border: yellow },
    Text: { color: yellow },
  },
  info: {
    Bar: {
      background: lightGreen,
      border: yellow,
    },
    Circle: { color: green, border: green },
    Text: { color: green },
  },
}

type Props = {
  shiftNotices: Array<ShiftNotice>
  margin?: string
  marginTop?: string
  marginBottom?: string
  marginRight?: string
  marginLeft?: string
  borderTop?: boolean
}

const ShiftNoticeNotifications = ({
  shiftNotices,
  margin,
  marginTop,
  marginBottom,
  marginRight,
  marginLeft,
  borderTop,
}: Props) => {
  return shiftNotices.map<Children>((shiftNotice) => (
    <NotificationBar
      key={shiftNotice.noticeText}
      markdown={shiftNotice.noticeText}
      margin={margin}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginRight={marginRight}
      marginLeft={marginLeft}
      borderTop={borderTop}
      colorOverrides={colors[shiftNotice.criticality]}
    />
  )) as unknown as ReactJSXElement
}

export default ShiftNoticeNotifications
