import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'

import { MAX_CAUSE_LABELS, TASK_TITLE_MAX_CHAR } from '../../constants'
import { MOMENT_TIME_FORMAT } from '../../constants'
import moment from '../../lib/moment-fi'
import { getColor, theme } from '../../Theme'
import { Color, Task } from '../../types'
import { Delay, TracksFromPunctuality } from '../../types/Input'
import { DelayLabelRow } from '../DelayLabelRow'
import RouteTitle from './RouteTitle'

interface TaskNameProps {
  noCaps?: boolean
  padding?: string
  active: boolean
  past: boolean
}

const RouteRow = styled.div`
  ${theme.spacing.ends('smallest')};
  margin: 0 ${theme.spacing.sizes.tiny};
  border-left: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightBlack'])};
  flex-grow: 1;
`
const TaskName = styled.div<TaskNameProps>`
  flex: 1;
  ${theme.text('mediumLarge', 'content', 'bold')};
  color: ${(p) => getColor(p.theme, [textDay(p)], [textNight(p)])};
  text-transform: ${(p) => (p.noCaps ? 'lowercase' : 'uppercase')};
  padding: ${(p) => (p.padding ? `${p.padding} 0` : '0')};
  width: 90px;
  @media (min-width: ${theme.breakpoints.large}) {
    width: 100px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
`

const MBTitle = styled(TaskName)`
  overflow: visible;
  text-overflow: visible;
  align-self: center;
  text-align: center;
`

const LunchTime = styled.span`
  font-weight: normal;
`

const Column = styled.div`
  ${theme.layout.flexColumn};
  align-items: flex-start;
  justify-content: flex-start;
  ${theme.spacing.sides('tiny')}
`

const Container = styled.div`
  ${theme.layout.flexColumn};
  align-items: flex-start;
  width: 100%;
`

const Row = styled.div`
  ${theme.layout.flexRow};
  width: 100%;
`

const DelayContainer = styled.div`
  width: 50px;
  flex: 2;
`
const textDay = ({ active }: { active: boolean }): Color => {
  if (active) return 'primaryGreen'
  return 'black'
}

const textNight = ({ active }: { active: boolean }): Color => {
  if (active) return 'white'
  return 'grayPink'
}

const LunchTitle = ({ t, task }: { t: TFunction; task: Task }) => (
  <>
    {t('unpaidMealBreak')}{' '}
    <LunchTime>
      {`(${moment(task.taskStartDateTime).format(MOMENT_TIME_FORMAT)} - ${moment(
        task.taskEndDateTime
      ).format(MOMENT_TIME_FORMAT)})`}
    </LunchTime>
  </>
)

const RouteTitleRow = ({
  t,
  task,
  taskTitle,
  isCommuter,
  delays,
  delayMinutes,
  trainCategory,
  nightMode,
  compactView,
  active,
  past,
  totalCauses,
  isCommuterMealBreak,
  isUnpaidMealBreak,
  tracksFromPunctuality,
  open,
}: PropsIn) => {
  const maxCauseLabels = MAX_CAUSE_LABELS

  return isCommuterMealBreak || isUnpaidMealBreak ? (
    <MBTitle active={active} past={past}>
      {isUnpaidMealBreak ? <LunchTitle t={t} task={task} /> : t('mealBreak')}
    </MBTitle>
  ) : (
    <Container>
      <Row>
        <Column>
          <TaskName past={past} active={active} noCaps={task.taskName === 'm'}>
            {taskTitle.length > TASK_TITLE_MAX_CHAR ? task.taskName : taskTitle}
          </TaskName>
          {((!compactView && (totalCauses <= maxCauseLabels || !open)) || isCommuter) && (
            <DelayContainer>
              <DelayLabelRow
                t={t}
                task={task}
                delays={delays}
                delayMinutes={delayMinutes}
                trainCategory={trainCategory}
                nightMode={nightMode}
                isMobile={false} // show full labels in task view
                estimate={false}
                notDriving={false}
              />
            </DelayContainer>
          )}
        </Column>
        <RouteRow>
          <RouteTitle
            t={t}
            task={task}
            delays={delays}
            delayMinutes={delayMinutes}
            trainCategory={trainCategory}
            nightMode={nightMode}
            compactView={compactView}
            active={active}
            isCommuter={isCommuter}
            tracksFromPunctuality={tracksFromPunctuality}
          />
        </RouteRow>
      </Row>
    </Container>
  )
}

type PropsIn = {
  t: TFunction
  task: Task
  taskTitle: string
  isCommuter?: boolean
  delays: Array<Delay>
  delayMinutes: number
  trainCategory: string
  nightMode: boolean
  compactView: boolean
  active: boolean
  totalCauses: number
  past: boolean
  isCommuterMealBreak: boolean
  isUnpaidMealBreak: boolean
  tracksFromPunctuality: Partial<TracksFromPunctuality>
  open: boolean
  isMobile: boolean
}

export default RouteTitleRow
