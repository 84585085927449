import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import _ from 'lodash'
import React, { ReactNode } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { toggleShowTaskContact } from '../../actions/shiftpage'
import { getName } from '../../lib/stations'
import { containsRelevantLocation, nightModeSelector, taskState } from '../../Selectors'
import { color, getColor, MaybeColor, theme } from '../../Theme'
import { AppState, Color, Dispatch } from '../../types'
import { ContactProps } from '../../types/Input'
import Label from '../Label'
import { DefaultText } from '../Text'
import ContactCard from './ContactCard'

interface RoleLabelProps {
  role?: string
  visible: boolean
  showLocation: boolean
  taskTrainNumber?: string
  contact: ContactProps
  color: Color
  backgroundColor: Color
}

const roleColor = (nightMode: boolean, role?: string): Color => {
  switch (role) {
    case 'vek':
    case 'avustava':
      return 'primaryGreen'
    case 'kond':
    case 'akond':
      return 'primaryYellow'
    case 'matk':
      return 'grayBlue'
    case 'jtar':
    case 'ajtar':
      return nightMode ? 'white' : 'black'
    default:
      return 'grayDark'
  }
}

const roleTextColor = (nightMode: boolean, visible: boolean, role?: string): Color => {
  switch (role) {
    case 'jtar':
    case 'ajtar':
      return nightMode && !visible ? 'black' : 'white'
    default:
      return 'white'
  }
}

const locationColor = (contact: ContactProps, taskTrainNumber?: string): Color => {
  if (
    taskTrainNumber &&
    contact?.location?.type === 'on-vehicle' &&
    contact?.location?.trainNumber?.toString() === taskTrainNumber
  ) {
    return 'primaryGreen'
  }
  return 'grayDark'
}

const Selected = styled.div`
  background-color: ${(p) => getColor(p.theme, ['lightBlue'], ['nightHighlight'])};
  margin: 0 -13px 0 -11px;
  padding 0 13px 0 11px;
`

const PaddedTopBorder = styled.div`
  ${theme.spacing.ends('smallest')}
`

const ContactRow = styled(Label)`
  ${theme.text('largest', 'decorative', 'bold')};
  ${theme.layout.flexRow};
  width: 100%;
  margin: auto;
  cursor: pointer;
  justify-content: space-between;
`

const RoleLabel = styled.div<RoleLabelProps>`
  ${theme.text('small', 'decorative', 'bold')};
  min-width: 80px;
  height: 26px;
  background: ${(p) =>
    color(
      p.showLocation
        ? locationColor(p.contact, p.taskTrainNumber)
        : p.visible
        ? 'primaryBlue'
        : p.backgroundColor
    )};
  color: ${(p) => p.color};
  margin-left: auto;
  text-align: center;
  line-height: 26px;
  border-radius: 10px;
`

const Name = styled(DefaultText)`
  ${theme.text('small', 'content', '400')};
  text-transform: capitalize;
  color: ${(p) => getColor(p.theme, ['primaryBlue'], ['grayPink'])};
`

const Trip = styled.div`
  ${theme.text('small', 'content', 'bold')};
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayPink'])};
  text-transform: uppercase;
  text-align: center;
  min-width: 80px;
`

const div = ({ children }: { children: ReactNode }) => <div>{children}</div>

type Props = {
  t: TFunction
  nightMode: boolean
  visible: boolean
  contact: ContactProps
  role?: string
  name?: string
  onToggleShow: (contactNumber: string) => () => void
  trainFrom?: string
  trainTo?: string
  trainFromTitle?: string
  trainToTitle?: string
  taskTrainNumber?: string
  showLocation: boolean
}

const ContactLabel = ({
  t,
  nightMode,
  visible,
  name,
  contact,
  role,
  onToggleShow,
  trainFrom,
  trainTo,
  trainFromTitle,
  trainToTitle,
  taskTrainNumber,
  showLocation,
}: Props) => {
  const DynamicColor = visible ? Selected : div
  return (
    <div>
      <DynamicColor>
        <PaddedTopBorder>
          {name ? (
            <ContactRow
              color={color as unknown as MaybeColor}
              onClick={onToggleShow(contact.number as string)}
            >
              <Name>{name ? name.toLowerCase() : undefined}</Name>
              <RoleLabel
                showLocation={showLocation}
                title={t(`roles.${role}`)}
                contact={contact}
                taskTrainNumber={taskTrainNumber}
                visible={visible}
                role={role}
                color={roleTextColor(nightMode, visible, role)}
                backgroundColor={roleColor(nightMode, role)}
              >
                {role}
              </RoleLabel>
              <Trip title={`${trainFromTitle} – ${trainToTitle}`}>
                {trainFrom} – {trainTo}
              </Trip>
            </ContactRow>
          ) : undefined}
        </PaddedTopBorder>
        {visible ? <ContactCard contact={contact} /> : undefined}
      </DynamicColor>
    </div>
  )
}

type PropsIn = {
  contact: ContactProps
  shiftId: string
  taskIndex: number
  open?: boolean
}

const mapStateToProps = (state: AppState, { contact, shiftId, taskIndex }: PropsIn) => {
  const ts = taskState(state, shiftId, taskIndex)
  const showLocation: boolean = containsRelevantLocation(state, contact, shiftId, taskIndex)
  return {
    name: contact.name,
    contact,
    role: contact.role,
    nightMode: nightModeSelector(state) as boolean,
    visible: _.includes(ts.visibleContacts, contact.number),
    trainFrom: contact.fromStation,
    trainTo: contact.toStation,
    trainFromTitle: getName(contact.fromStation as string),
    trainToTitle: getName(contact.toStation as string), // TODO: make this work properly when used from search
    taskTrainNumber: state.shifts.byId?.[shiftId]?.tasks?.[taskIndex]?.trainNumber,
    showLocation,
  }
}

const mapDispatchToProps = (dispatch: Dispatch, { shiftId, taskIndex }: PropsIn) => ({
  onToggleShow: (number: string | null) => () =>
    dispatch(toggleShowTaskContact(shiftId, taskIndex, number)),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContactLabel))
