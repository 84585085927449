import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  fetchIncidentsAction,
  fetchIncidentsAfterAction,
  fetchIncidentsEnabledAction,
  fetchIncidentsForTrainsAction,
  postIncidentFeedbackAction,
} from '../actions/incidents'
import { LiitoIncident, LiitoIncidentForFeedback } from '../components/incidents/types'
import { INCIDENT_POLL_RATE_MS } from '../constants'
import { ActionStatus } from '../types/Input'

export interface IncidentsState {
  status: ActionStatus
  incidents: LiitoIncident[]
  incidentsEnabledStatus: ActionStatus
  incidentsEnabled: boolean
  shiftIncidents: LiitoIncident[]
  shiftIncidentsWaitingForFeedback: LiitoIncidentForFeedback[]
  shiftIncidentsStatus: ActionStatus
  error?: string
  incidentsEnabledError?: string
  shiftIncidentsError?: string
  showNotification: boolean
  updatedIncidentIds: number[]
  latestLastModifiedAt: string
  incidentUpdatePollingHandle?: NodeJS.Timeout
}

const initialState: IncidentsState = {
  status: 'none',
  incidents: [],
  incidentsEnabledStatus: 'none',
  incidentsEnabled: false,
  shiftIncidentsStatus: 'none',
  shiftIncidents: [],
  shiftIncidentsWaitingForFeedback: [],
  showNotification: false,
  updatedIncidentIds: [],
  latestLastModifiedAt: '',
}

const getLastModifiedAt = (incidents: LiitoIncident[], previousLatest?: string): string =>
  incidents.reduce(
    (latest, { lastModifiedAt }) => (lastModifiedAt > latest ? lastModifiedAt : latest),
    previousLatest || ''
  )

const sortIncidentsByActiveAndCreatedAt = (a: LiitoIncident, b: LiitoIncident) => {
  if (a.status !== b.status) {
    return a.status === 'ACTIVE' ? -1 : 1
  }

  if (a.createdAt !== b.createdAt) {
    return a.createdAt < b.createdAt ? 1 : -1
  }

  return 0
}

export const incidentsSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    resetNotification: (state) => {
      state.showNotification = false
    },
    addToUpdatedIncidents: (state, action: PayloadAction<number>) => {
      state.showNotification = true
      state.updatedIncidentIds = state.updatedIncidentIds.concat(action.payload)
    },
    removeFromUpdatedIncidents: (state, action: PayloadAction<number>) => {
      state.updatedIncidentIds = state.updatedIncidentIds.filter((id) => id !== action.payload)
      if (state.updatedIncidentIds.length === 0) {
        state.showNotification = false
      }
    },
    setIncidentUpdatePolling: (state, action: PayloadAction<{ callback?: () => void }>) => {
      clearInterval(state.incidentUpdatePollingHandle)
      if (action.payload.callback) {
        state.incidentUpdatePollingHandle = setInterval(
          action.payload.callback,
          INCIDENT_POLL_RATE_MS
        )
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIncidentsEnabledAction.pending, (state) => {
      state.incidentsEnabledStatus = 'loading'
      state.incidentsEnabledError = undefined
    })

    builder.addCase(fetchIncidentsEnabledAction.fulfilled, (state, { payload }) => {
      state.incidentsEnabled = payload
      state.incidentsEnabledStatus = 'succeeded'
    })

    builder.addCase(fetchIncidentsEnabledAction.rejected, (state, { payload }) => {
      if (payload) {
        state.incidentsEnabledError = payload.message
      }
      state.incidentsEnabledStatus = 'failed'
    })

    builder.addCase(fetchIncidentsAction.pending, (state) => {
      state.status = 'loading'
      state.error = undefined
    })

    builder.addCase(fetchIncidentsAction.fulfilled, (state, { payload }) => {
      state.incidents = payload
      state.status = 'succeeded'
      state.latestLastModifiedAt = getLastModifiedAt(payload)
      state.updatedIncidentIds = state.updatedIncidentIds.filter((updateId) =>
        payload.some(({ id }) => id === updateId)
      )
    })

    builder.addCase(fetchIncidentsAction.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload.message
      }
      state.status = 'failed'
    })

    builder.addCase(fetchIncidentsAfterAction.pending, (state) => {
      state.status = 'loading'
      state.error = undefined
    })

    builder.addCase(fetchIncidentsAfterAction.fulfilled, (state, { payload }) => {
      state.incidents = state.incidents
        .filter(({ id }) => !payload.some(({ id: newId }) => id === newId))
        .concat(payload)
        .sort(sortIncidentsByActiveAndCreatedAt)

      state.status = 'succeeded'
      state.latestLastModifiedAt = getLastModifiedAt(payload, state.latestLastModifiedAt)
      state.updatedIncidentIds = state.updatedIncidentIds.concat(payload.map(({ id }) => id))
      state.showNotification = state.updatedIncidentIds.length > 0
    })

    builder.addCase(fetchIncidentsAfterAction.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload.message
      }
      state.status = 'failed'
    })

    builder.addCase(fetchIncidentsForTrainsAction.pending, (state) => {
      state.status = 'loading'
      state.error = undefined
    })

    builder.addCase(fetchIncidentsForTrainsAction.fulfilled, (state, { payload }) => {
      state.shiftIncidents = payload.incidentsForTrains
      state.shiftIncidentsWaitingForFeedback = payload.pastIncidentsWaitingForFeedback
      state.shiftIncidentsStatus = 'succeeded'
    })

    builder.addCase(fetchIncidentsForTrainsAction.rejected, (state, { payload }) => {
      if (payload) {
        state.shiftIncidentsError = payload.message
      }
      state.shiftIncidentsStatus = 'failed'
    })

    builder.addCase(postIncidentFeedbackAction.fulfilled, (state, { payload }) => {
      state.shiftIncidentsWaitingForFeedback = state.shiftIncidentsWaitingForFeedback.filter(
        ({ incidentId }) => !payload.incidentIds.includes(incidentId)
      )
    })
  },
})

export const { resetNotification, removeFromUpdatedIncidents, setIncidentUpdatePolling } =
  incidentsSlice.actions

export default incidentsSlice.reducer
