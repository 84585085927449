import styled from '@emotion/styled'

import moment from '../../lib/moment-fi'
import { color, getColor, theme } from '../../Theme'
import { CalendarDay } from './CalendarDay'

const Row = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  display: flex;
  margin-top: ${theme.spacing.sizes.small};
  min-width: ${theme.maxWidths.column};
`

const Container = styled.div`
  ${theme.spacing.all('small')};
  ${theme.spacing.right('biggest')};
  background-color: ${(p) => getColor(p.theme, ['grayBlue'], ['nightGray'])};
  border-radius: ${theme.borderRadius.button};
  flex: 1;
  margin-right: ${theme.spacing.sizes.small};
  position: relative;
`

const Title = styled.div`
  ${theme.text('normal', 'decorative', 'bold')};
  color: ${color('grayDark')};
`

type Props = {
  text?: string
  startDateTime: string
  hideDate?: boolean
}

const CalendarFreeDay = ({ text = '', startDateTime, hideDate }: Props) => {
  return (
    <Row>
      <CalendarDay date={moment(startDateTime)} hideDate={hideDate} />
      <Container>
        <Title>{text}</Title>
      </Container>
    </Row>
  )
}

export default CalendarFreeDay
