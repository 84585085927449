import styled from '@emotion/styled'

import { theme } from '../../Theme'

const SearchPageContent = styled.div`
  ${theme.layout.fluidWidthMargin(theme.maxWidths.content, theme.spacing.sizes.small)};
  ${theme.spacing.right('small')};
  ${theme.spacing.left('small')};
  ${theme.spacing.bottom('small')};
`

export default SearchPageContent
