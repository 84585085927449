import { Link } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import { MAJOR_INCIDENT_FEEDBACK_LINK } from '../../constants'
import Close from '../../icons/Close'
import moment from '../../lib/moment-fi'
import { EmphasisNormal } from '../Emphasis'
import { LiitoIncidentForFeedback } from '../incidents/types'
import { DefaultText } from '../Text'

const FeedbackContainer = styled('div')(({ theme }) => ({
  boxShadow: '0 2px 6px 1px rgba(0, 0, 0, 0.1)',
  marginTop: theme.spacing('large'),
  marginBottom: theme.spacing('large'),
  background: theme.palette.background.secondary,
  border: `2px solid ${
    theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[400]
  }`,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
}))

const Flex = styled('div')(({ theme }) => ({
  ...theme.layout.flexColumn,
  alignItems: 'flex-start',
  padding: theme.spacing('small'),
  boxSizing: 'border-box',
  width: '100%',
}))

const CloseContainer = styled('div')(({ theme }) => ({
  color: theme.palette.common.kenttaBlue,
  cursor: 'pointer',
  margin: theme.spacing('small'),
  lineHeight: 0,
}))

interface Props {
  incident?: LiitoIncidentForFeedback
  handleClose: (wasLinkClicked: boolean) => void
}

function IncidentFeedback({ incident, handleClose }: Props) {
  const { t } = useTranslation()

  return (
    <FeedbackContainer>
      <Flex>
        <EmphasisNormal>{t('incidents.incidentFeedbackTitle')}</EmphasisNormal>
        {incident && (
          <DefaultText>
            {moment(incident.startTime).format('DD.MM.')} {incident.header}
          </DefaultText>
        )}
        <Link
          variant="body2"
          href={MAJOR_INCIDENT_FEEDBACK_LINK}
          target="_blank"
          onClick={() => handleClose(true)}
        >
          {t('incidents.majorIncidentFeedback')}
        </Link>
      </Flex>
      {incident && (
        <CloseContainer onClick={() => handleClose(false)}>
          <Close iconSize={'normal'} />
        </CloseContainer>
      )}
    </FeedbackContainer>
  )
}

export default IncidentFeedback
