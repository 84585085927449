import { TFunction } from 'i18next'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { TypedDispatch, useTypedDispatch } from '../..'
import { fetchTrainPunctuality } from '../../actions/api'
import { TASK_OTHER_TRAINS_PUNCTUALITY_CUTOFF_MS } from '../../constants'
import moment from '../../lib/moment-fi'
import { getPunctualityKey } from '../../Selectors'
import { AppState, Task, TaskDetailsInput } from '../../types'
import { TimetableRow, TrainPunctuality } from '../../types/Input'
import { getTimeLabelText, TimeLabel } from '../DelayLabelRow'
import { Text } from './TaskDetails'

type Props = {
  details: TaskDetailsInput
  t: TFunction
  task: Task
}

type DetailsTrain = TaskDetailsInput['trains'][0]

type DetailsTrainParsed = DetailsTrain & { trainNumberActual?: string; date?: string }

type TrainDelayInformation = {
  delayMinutes: number
  isEstimate: boolean
  trainCategory: string
}

type DetailsTrainWithDelay = DetailsTrain & Partial<TrainDelayInformation>

const fetchMissingTimetableData = (trains: DetailsTrainParsed[]) => {
  return async (dispatch: TypedDispatch, getState: () => AppState) => {
    const punctualityState = getState().punctuality

    const trainsWithNoPunctuality = trains.filter(
      ({ trainNumberActual, date }) =>
        date &&
        trainNumberActual &&
        !punctualityState.punctualityByTrain[getPunctualityKey(trainNumberActual, date)]
    )

    if (trainsWithNoPunctuality.length) {
      dispatch(
        fetchTrainPunctuality(
          trainsWithNoPunctuality.map(({ trainNumberActual, date }) => ({
            trainNumber: trainNumberActual,
            trainDate: date,
          }))
        )
      )
    }
  }
}

/**
 * hacky way to get train number (and possibly time),
 * since it just comes as a string from eSälli
 */
const parseTrains = (trains: DetailsTrain[], task: Task): DetailsTrainParsed[] => {
  const taskStart = moment(task.taskStartDateTime)

  return trains.map<DetailsTrainParsed>((train) => {
    const match = train.trainNumber.match(/(\d+)(?:\s+(\d\d:\d\d))?\s*$/)
    if (!match) return train

    const trainNumber = match[1]
    const time = train.time ?? match[2]

    const taskDate = taskStart.format('YYYY-MM-DD')

    let trainMoment = taskStart
    if (time) {
      trainMoment = moment(taskDate + ' ' + time)
      if (taskStart.isBefore(trainMoment)) {
        trainMoment.subtract(1, 'day')
      }
    }

    // don't fetch live timetable information
    // if the train's scheduled arrival is long before task
    // or if it is long before or after now
    if (
      taskStart.diff(trainMoment) > TASK_OTHER_TRAINS_PUNCTUALITY_CUTOFF_MS ||
      Math.abs(moment().diff(trainMoment)) > TASK_OTHER_TRAINS_PUNCTUALITY_CUTOFF_MS
    ) {
      return train
    }

    return { ...train, trainNumberActual: trainNumber, date: trainMoment.format('YYYY-MM-DD') }
  })
}

const isEstimate = (t: TimetableRow): boolean =>
  !t.actualTime && moment(t.liveEstimateTime).isAfter(moment())

const getDelaysForTrains = (
  trains: DetailsTrainParsed[],
  punctualities: Record<string, TrainPunctuality>
): DetailsTrainWithDelay[] => {
  return trains.map<DetailsTrainWithDelay>((train) => {
    const { trainNumberActual, date } = train
    const punctuality = punctualities[getPunctualityKey(trainNumberActual, date)]
    if (!punctuality || !punctuality.timeTableRows.length) {
      return train
    }

    const mostRecentRealRow = punctuality.timeTableRows.findLast((row) => !isEstimate(row))

    if (!mostRecentRealRow) {
      return train
    }

    return {
      ...train,
      delayMinutes: mostRecentRealRow.differenceInMinutes,
      isEstimate: isEstimate(mostRecentRealRow),
      trainCategory: punctuality.trainCategory,
    }
  })
}

const getTrainsForDisplay = (
  trains: DetailsTrainParsed[],
  punctualities: Record<string, TrainPunctuality>,
  details: TaskDetailsInput,
  t: TFunction
): (DetailsTrainWithDelay & { text: string })[] | undefined => {
  const trainsWithDelays = getDelaysForTrains(trains, punctualities)
  const { type, isCommuter } = details

  if (!trainsWithDelays.length) return

  if (trainsWithDelays.length === 1) {
    return [
      {
        ...trainsWithDelays[0],
        text: `${t(`${type}Train`)} ${trainsWithDelays[0].lineId || ''} ${
          trainsWithDelays[0].trainNumber
        }${trainsWithDelays[0].time ? `, ${trainsWithDelays[0].time}` : ''}`,
      },
    ]
  }

  if (isCommuter) {
    return trainsWithDelays.map((tr) => {
      return {
        ...tr,
        text: `${t('frame')} ${tr.frame || ''} ${t(`${type}Train`).toLowerCase()} ${
          tr.lineId || ''
        } ${tr.trainNumber}, ${tr.time || ''}`,
      }
    })
  }

  return
}

export const TaskDetailsTrains = ({ details, t, task }: Props) => {
  const { type, trains } = details
  const punctualities = useSelector((state: AppState) => state.punctuality.punctualityByTrain)
  const dispatch = useTypedDispatch()

  const trainsParsed = parseTrains(trains, task)

  //fetch missing timetable information
  useEffect(() => {
    if (type === 'from') {
      dispatch(fetchMissingTimetableData(trainsParsed))
    }
    // Due to how this page is constructed, changing the punctuality stat will cause the page to
    // re-render. As the trains array is constructed dynamically below that, it would cause an
    // infinite loop in some cases. Simply only running the above dispatch when this component is
    // instantiated is far simpler than refactoring the punctuality state or parent components.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const trainsForDisplay = getTrainsForDisplay(trainsParsed, punctualities, details, t)

  return (
    <>
      {trainsForDisplay?.length &&
        trainsForDisplay.map((train) => (
          <Text type={type} key={train.trainNumber}>
            {train.text}{' '}
            {type === 'from' && 'delayMinutes' in train && (
              <TimeLabel
                delayMinutes={train.delayMinutes}
                trainCategory={train.trainCategory}
                notDriving={false}
                estimate={train.isEstimate}
                style={{ display: 'inline-block' }}
              >
                {getTimeLabelText(train.delayMinutes)}
              </TimeLabel>
            )}
          </Text>
        ))}
    </>
  )
}
