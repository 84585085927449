import { UPDATE_PERSONNELGROUP, UPDATE_SERVICEDRIVERROLE, UPDATE_SESSION } from '../actions/user'
import { UserAction } from '../actions/user'
import { UserState } from '../types'

const initialState = {
  created_at: 0,
  token: '',
  number: '',
  actAsCommuter: false,
  actAsMaintenance: false,
  actAsServiceDriver: false,
  personnelGroup: '',
  serviceDriver: false,
  originalNumber: '',
  admin: false,
  read_admin: false,
  commuter_driver: false,
  commuter_manager: false,
  driver: false,
  logistics_driver: false,
  commuter_conductor: false,
  conductor: false,
  waiter: false,
  maintenance: false,
  other: false,
  name: '',
  familyName: '',
  loading: false,
  error: '',
} as const

const userReducer = (state: UserState = initialState, action: UserAction) => {
  switch (action.type) {
    case UPDATE_SESSION:
      return {
        ...state,
        ...action.payload,
      }

    case UPDATE_PERSONNELGROUP:
      return {
        ...state,
        personnelGroup: action.payload,
      }

    case UPDATE_SERVICEDRIVERROLE:
      return {
        ...state,
        serviceDriver: action.payload,
      }

    default:
      return state
  }
}

export default userReducer
