import { Router } from 'found'
import { TFunction } from 'i18next'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import {
  ChangeViewFunction,
  deletePersonnelFromHistory,
  fetchPersonnelInformation,
  getPersonnelSearchHistory,
  personnelFromHistory,
} from '../../actions/api'
import ErrorText from '../../components/ErrorText'
import Page from '../../components/page/Page'
import PersonnelSearch from '../../components/personnel/PersonnelSearch'
import SearchPageContainer from '../../components/search/SearchPageContainer'
import SearchPageContent from '../../components/search/SearchPageContent'
import moment from '../../lib/moment-fi'
import { Action, AppState, Dispatch, Personnel } from '../../types'
import { SearchDeleteParams } from '../../types/Input'

type Props = {
  t: TFunction
  router: Router
  loadingPersonnel: boolean
  personnelError: string
  personnelHistory: Array<Personnel>
  fetchPersonnelInformation: (arg0: string, arg1: string, func: () => unknown) => Action
  getPersonnelSearchHistory: () => void
  personnelFromHistory: (personnel: Personnel, func: () => unknown) => Action
  deletePersonnelFromHistory: (searchDeleteParams: SearchDeleteParams) => Action
}

export type State = {
  date: string
  dateActive?: boolean
  error?: string
  showCalendar?: boolean
  startStation: string
  startStationActive?: boolean
  trainNumberActive?: boolean
  trainNumber: string
}

class PersonnelSearchPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      trainNumber: '',
      date: moment().format('DD.MM.YYYY'),
      showCalendar: false,
      startStation: '',
      error: undefined,
    }
  }

  componentDidMount() {
    this.props.getPersonnelSearchHistory()
  }

  changeTrainNumber(value: string) {
    const matchPositiveNumber = /^[1-9]\d*$/
    if ((this.state.trainNumber !== '' && value === '') || matchPositiveNumber.test(value)) {
      return this.setState({ trainNumber: value, error: undefined })
    }
    this.setState({ error: 'Junanumeron pitää olla positiivinen' })
  }

  changeDate(value: moment.MomentInput) {
    this.setState({
      date: moment(value).format('DD.MM.YYYY'),
      showCalendar: false,
      error: undefined,
    })
  }

  openCalendar(showCalendar: boolean) {
    this.setState({ showCalendar })
  }

  activateTrainNumber() {
    this.setState({ trainNumberActive: true, dateActive: false, showCalendar: false })
  }

  activateDate() {
    this.setState({ trainNumberActive: false, dateActive: true })
  }

  changeValue(obj: State) {
    this.setState(obj)
  }

  fetchPersonnelInformation() {
    this.props.fetchPersonnelInformation(
      moment(this.state.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      this.state.trainNumber,
      () => this.props.router.push(`/personnelList/${this.state.trainNumber}/${this.state.date}`)
    )
  }

  render() {
    const {
      t,
      router,
      loadingPersonnel,
      personnelError,
      personnelHistory,
      personnelFromHistory,
      deletePersonnelFromHistory,
    }: Props = this.props

    // set error from sub pages if needed
    if (this.state.error !== t(personnelError))
      this.changeValue({ ...this.state, error: t(personnelError) })

    return (
      <Page overflowVisible>
        <SearchPageContainer>
          {this.state.error && <ErrorText>{this.state.error}</ErrorText>}
        </SearchPageContainer>
        <SearchPageContainer>
          <SearchPageContent>
            <PersonnelSearch
              activateDate={() => this.activateDate()}
              activateTrainNumber={() => this.activateTrainNumber()}
              changeDate={(value) => this.changeDate(value)}
              changeTrainNumber={(value) => this.changeTrainNumber(value)}
              fetchPersonnelInformation={() => this.fetchPersonnelInformation()}
              personnelHistory={personnelHistory}
              personnelFromHistory={personnelFromHistory}
              deletePersonnelFromHistory={deletePersonnelFromHistory}
              loading={loadingPersonnel}
              openCalendar={(bool) => this.openCalendar(bool)}
              router={router}
              setError={(error) => this.setState({ error })}
              state={this.state}
              t={t}
            />
          </SearchPageContent>
        </SearchPageContainer>
      </Page>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const loadingPersonnel = state.personnel.loading
  const personnelError = state.personnel.error
  const personnelHistory = state.personnel.personnelHistory

  return {
    loadingPersonnel,
    personnelError,
    personnelHistory,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // personnel functions
  fetchPersonnelInformation: (
    date: string,
    trainNumber: string,
    changeView?: ChangeViewFunction | null
  ) => dispatch(fetchPersonnelInformation(date, trainNumber, changeView) as unknown as Action),
  getPersonnelSearchHistory: () => dispatch(getPersonnelSearchHistory() as unknown as Action),
  personnelFromHistory: (train: Personnel, changeView: ChangeViewFunction) =>
    dispatch(personnelFromHistory(train, changeView) as unknown as Action),
  deletePersonnelFromHistory: (params: SearchDeleteParams) =>
    dispatch(deletePersonnelFromHistory(params) as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PersonnelSearchPage))
