import { createAsyncThunk } from '@reduxjs/toolkit'

import { TypedDispatch } from '../..'
import { LiitoIncident, LiitoIncidentForFeedback } from '../../components/incidents/types'
import { apiGET, apiPOST } from '../../lib/api'
import { setIncidentUpdatePolling } from '../../reducers/incidentsSlice'
import { AppState } from '../../types'

interface IncidentsError {
  message: string
}

const fetchIncidentsEnabled = (): Promise<IncidentsEnabledResponseWrapper> =>
  apiGET('incidentsEnabled')

interface IncidentsEnabledResponseWrapper {
  incidentsEnabled: boolean
}

export const fetchIncidentsEnabledAction = createAsyncThunk<
  boolean,
  void,
  { rejectValue: IncidentsError }
>('incidents/fetchIncidentsEnabled', async (none, thunkApi) => {
  const dispatch = thunkApi.dispatch as TypedDispatch

  try {
    const { incidentsEnabled } = await fetchIncidentsEnabled()

    if (!incidentsEnabled) dispatch(setIncidentUpdatePolling({}))

    return incidentsEnabled
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch incidents allowed',
    })
  }
})

const fetchIncidents = (after?: string): Promise<LiitoIncident[]> =>
  apiGET(`incidents${after ? `?after=${after}` : ''}`)

export const fetchIncidentsAction = createAsyncThunk<
  LiitoIncident[],
  void,
  { rejectValue: IncidentsError }
>('incidents/fetchIncidents', async (_: void, thunkApi) => {
  const dispatch = thunkApi.dispatch as TypedDispatch

  try {
    const incidents = await fetchIncidents()

    dispatch(
      setIncidentUpdatePolling({
        callback: () => dispatch(fetchIncidentsAfterAction()),
      })
    )

    return incidents
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch incidents',
    })
  }
})

export const fetchIncidentsAfterAction = createAsyncThunk<
  LiitoIncident[],
  void,
  { rejectValue: IncidentsError }
>('incidents/fetchIncidentsAfter', async (_, thunkApi) => {
  const after = (thunkApi.getState() as AppState).incidents.latestLastModifiedAt

  try {
    return await fetchIncidents(after)
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: `Failed to fetch incidents after ${after}`,
    })
  }
})

export interface IncidentsTrainFilter {
  operatingDay: string
  trainNumbers: string[]
}

const fetchIncidentsForTrains = (
  params: IncidentsTrainFilter[]
): Promise<{
  incidentsForTrains: LiitoIncident[]
  pastIncidentsWaitingForFeedback: LiitoIncidentForFeedback[]
}> => {
  return apiPOST('incidentsForTrains', params)
}

export const fetchIncidentsForTrainsAction = createAsyncThunk<
  {
    incidentsForTrains: LiitoIncident[]
    pastIncidentsWaitingForFeedback: LiitoIncidentForFeedback[]
  },
  IncidentsTrainFilter[],
  { rejectValue: IncidentsError }
>('incidents/fetchIncidentsForTrains', async (incidentsFilters, thunkApi) => {
  try {
    return await fetchIncidentsForTrains(incidentsFilters)
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: `Failed to fetch incidents ${incidentsFilters}`,
    })
  }
})

const postIncidentFeedback = (
  incidentFeedback: {
    incidentId: number
    wasLinkClicked: boolean
  }[]
): Promise<unknown> =>
  apiPOST(`incidentFeedback`, incidentFeedback).catch((err) =>
    console.error('Failed to post incident feedback', err)
  )

export const postIncidentFeedbackAction = createAsyncThunk<
  { incidentIds: number[] },
  { incidentId: number; wasLinkClicked: boolean }[],
  undefined
>('incidents/postIncidentFeedback', async (incidentFeedback) => {
  postIncidentFeedback(incidentFeedback)

  return { incidentIds: incidentFeedback.map(({ incidentId }) => incidentId) }
})
