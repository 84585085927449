import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import CalendarFreeDay from './CalendarFreeDay'

type Props = {
  dayType: string
  startDateTime: string
  hideDate?: boolean
}

const getDayTypeTranslation = (dayType: string, t: TFunction) => {
  switch (dayType) {
    case 'W':
      return t('scheduleWday')
    case 'H':
      return t('scheduleHday')
    case 'empty':
      return ''
    default:
      return ''
  }
}

const CalendarRestDay = ({ dayType, startDateTime, hideDate }: Props) => {
  const { t } = useTranslation()
  return (
    <CalendarFreeDay
      text={getDayTypeTranslation(dayType, t)}
      startDateTime={startDateTime}
      hideDate={hideDate}
    />
  )
}

export default CalendarRestDay
