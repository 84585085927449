import { AlertInfo } from '../types/App'

export type TimerTick = { type: 'TICK_TIMER'; payload: { now: number } }
export const TICK_TIMER = 'TICK_TIMER'
export const tickTimer = (now: number): TimerTick => {
  return { type: TICK_TIMER, payload: { now } }
}

export type ResizeScreen = {
  type: 'RESIZE_SCREEN'
  payload: {
    width: number
  }
}
export const RESIZE_SCREEN = 'RESIZE_SCREEN'
export const resizeScreen = (width: number): ResizeScreen => {
  return { type: RESIZE_SCREEN, payload: { width } }
}

export type ToggleNightMode = { type: 'TOGGLE_NIGHT_MODE' }
export const TOGGLE_NIGHT_MODE = 'TOGGLE_NIGHT_MODE'
export const toggleNightMode = (): ToggleNightMode => {
  return { type: TOGGLE_NIGHT_MODE }
}

export type ToggleShortNumbers = {
  type: 'TOGGLE_SHORT_NUMBERS'
}
export const TOGGLE_SHORT_NUMBERS = 'TOGGLE_SHORT_NUMBERS'
export const toggleShortNumbers = (): ToggleShortNumbers => ({
  type: TOGGLE_SHORT_NUMBERS,
})

export type ToggleAdminBar = {
  type: 'TOGGLE_ADMIN_BAR'
}
export const TOGGLE_ADMIN_BAR = 'TOGGLE_ADMIN_BAR'
export const toggleAdminBar = (): ToggleAdminBar => ({
  type: TOGGLE_ADMIN_BAR,
})

export type DisableVirtualTime = {
  type: 'DISABLE_VIRTUAL_TIME'
}
export const DISABLE_VIRTUAL_TIME = 'DISABLE_VIRTUAL_TIME'
export const disableVirtualTime = (): DisableVirtualTime => {
  return { type: DISABLE_VIRTUAL_TIME }
}

export type UpdateOffsetVirtualTime = {
  type: 'UPDATE_OFFSET_VIRTUAL_TIME'
  payload: {
    offset: number
  }
}
export const UPDATE_OFFSET_VIRTUAL_TIME = 'UPDATE_OFFSET_VIRTUAL_TIME'
export const updateOffsetVirtualTime = (offset: number): UpdateOffsetVirtualTime => {
  return { type: UPDATE_OFFSET_VIRTUAL_TIME, payload: { offset } }
}

export type NormalSpeedVirtualTime = {
  type: 'NORMAL_SPEED_VIRTUAL_TIME'
}
export const NORMAL_SPEED_VIRTUAL_TIME = 'NORMAL_SPEED_VIRTUAL_TIME'
export const normalSpeedVirtualTime = (): NormalSpeedVirtualTime => {
  return { type: NORMAL_SPEED_VIRTUAL_TIME }
}

export type FastForwardVirtualTime = {
  type: 'FAST_FORWARD_VIRTUAL_TIME'
}
export const FAST_FORWARD_VIRTUAL_TIME = 'FAST_FORWARD_VIRTUAL_TIME'
export const fastForwardVirtualTime = (): FastForwardVirtualTime => {
  return { type: FAST_FORWARD_VIRTUAL_TIME }
}

export type NetworkChange = {
  type: 'NETWORK_CHANGE'
  payload: {
    type: 'ONLINE' | 'OFFLINE'
  }
}
export const NETWORK_CHANGE = 'NETWORK_CHANGE'
export const networkChange = (type: 'ONLINE' | 'OFFLINE'): NetworkChange => {
  return { type: NETWORK_CHANGE, payload: { type } }
}

export type NewAppVersion = {
  type: 'NEW_APP_VERSION'
  payload: {
    exists: boolean
  }
}
export const NEW_APP_VERSION = 'NEW_APP_VERSION'
export const newAppVersion = (exists: boolean): NewAppVersion => {
  return { type: NEW_APP_VERSION, payload: { exists } }
}

export type UpdateRedirect = {
  type: 'UPDATE_REDIRECT'
  payload: {
    status: boolean
  }
}
export const UPDATE_REDIRECT = 'UPDATE_REDIRECT'
export const updateRedirect = (status: boolean): UpdateRedirect => {
  return { type: UPDATE_REDIRECT, payload: { status } }
}

export type ToggleTaskRowInfoDebug = {
  type: 'TOGGLE_TASK_ROW_INFO_DEBUG'
}
export const TOGGLE_TASK_ROW_INFO_DEBUG = 'TOGGLE_TASK_ROW_INFO_DEBUG'
export const toggleTaskRowInfoDebug = (): ToggleTaskRowInfoDebug => ({
  type: TOGGLE_TASK_ROW_INFO_DEBUG,
})

export type UpdatePushStatus = {
  type: 'UPDATE_PUSH_STATUS'
  payload: {
    pushStatus: AlertInfo
  }
}
export const UPDATE_PUSH_STATUS = 'UPDATE_PUSH_STATUS'
export const updatePushStatus = (pushStatus: AlertInfo): UpdatePushStatus => {
  return { type: UPDATE_PUSH_STATUS, payload: { pushStatus } }
}

export type DismissPushAlert = {
  type: 'DISMISS_PUSH_ALERT'
}
export const DISMISS_PUSH_ALERT = 'DISMISS_PUSH_ALERT'
export const dismissPushAlert = (): DismissPushAlert => ({
  type: DISMISS_PUSH_ALERT,
})

export type VirtualTimeAction =
  | DisableVirtualTime
  | UpdateOffsetVirtualTime
  | NormalSpeedVirtualTime
  | FastForwardVirtualTime
export type SystemAction =
  | ResizeScreen
  | TimerTick
  | ToggleNightMode
  | ToggleShortNumbers
  | ToggleAdminBar
  | VirtualTimeAction
  | NetworkChange
  | NewAppVersion
  | UpdateRedirect
  | ToggleTaskRowInfoDebug
  | UpdatePushStatus
  | DismissPushAlert
